/* ==================LEFT================== */
.main .element_container .left {
  position: sticky;
  top: var(--sticky-top-left);
  z-index: 2;
  @media screen and (min-width: 1000px) {
    top: -1.2rem;
  }
  height: max-content;
}

.main .element_container .left .profile {
  padding: var(--card-padding);
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 100%;
}

/* ==================SIDEBAR================== */

.left .sidebar {
  position: relative;
  margin-top: 1rem;
  background: var(--color-white);
  border-radius: var(--card-border-radius);
}

.left .sidebar .menu-toggle {
  position: absolute;
  bottom: 2rem;
  right: 5rem;
  padding: 1rem 0.8rem;
  width: 3rem;
  height: 4rem;
  border-radius: var(--card-border-radius) 0 0 var(--card-border-radius);
  background: var(--color-primary);
  cursor: pointer;
  overflow: hidden;
  display: none;
}

.left .sidebar .menu-toggle > div i {
  margin: 0;
  font-size: 2rem;
  color: white;
}

.left .sidebar .menu-toggle > div {
  position: absolute;
  top: 0.55rem;
  left: 0.5rem;
  width: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  translate: 0rem;
  transition: translate 0.5s ease;
}

.left .sidebar .menu-toggle.activeitem > div {
  translate: -6rem;
}

.left .sidebar .menu-item {
  display: flex;
  align-items: center;
  height: 4rem;
  cursor: pointer;
  transition: all 300ms ease;
  position: relative;
}

.left .sidebar .menu-item:hover {
  background: var(--color-light);
}

.left .sidebar i {
  font-size: 1.4rem;
  color: var(--color-gray);
  margin-left: 2rem;
  position: relative;
}

.left .sidebar i .notification-count {
  background: var(--color-danger);
  color: white;
  font-size: 0.7rem;
  width: fit-content;
  border-radius: 0.8rem;
  padding: 0.1rem 0.4rem;
  position: absolute;
  top: -0.2rem;
  right: -0.5rem;
}

.left .sidebar h3 {
  margin-left: 1.5rem;
  font-size: 1rem;
}

.left .sidebar > .menu-item.activeitem {
  background: var(--color-light);
}

.left .sidebar .activeitem i,
.left .sidebar .activeitem h3 {
  color: var(--color-primary);
}

.left .sidebar .activeitem > span {
  display: contents;
  height: 100%;
}

.left .sidebar .activeitem > span:before {
  content: "";
  display: block;
  width: 0.5rem;
  height: 100%;
  position: absolute;
  background: var(--color-primary);
}

.left .sidebar .menu-item:nth-child(2) {
  border-top-left-radius: var(--card-border-radius);
  overflow: hidden;
}

.left .sidebar .menu-item:last-child {
  border-bottom-left-radius: var(--card-border-radius);
  overflow: hidden;
}

.left .btn {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}

/* ---------------------NOTIFICATION POPUP------------------- */
.left .notifications-popup {
  display: none;
  position: absolute;
  top: 0;
  left: 110%;
  width: 30rem;
  max-height: 30rem;
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 0 0 1rem var(--color-primary);
  z-index: 8;
}

.left .notifications-popup.activeitem {
  display: block;
}

.left .notifications-popup::before {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2em;
  background-color: var(--color-white);
  transform: rotate(45deg);
  position: absolute;
  left: -0.58rem;
}

.left .notifications-popup .notifications {
  width: 100%;
  max-height: 22rem;
  overflow-y: scroll;
}

.left .notifications-popup .notifications::-webkit-scrollbar {
  display: none;
}

.left .notifications-popup .notifications > div {
  display: flex;
  align-items: start;
  gap: 1rem;
  margin-bottom: 1rem;
}

.left .notifications-popup small {
  display: block;
}
.dark_h4_texts {
  color: var(--color-dark);
}
.navigation-icon {
  display: flex;
  height: 1.4rem;
  width: 1.4rem;
  align-items: center;
}
.navigation-icon svg {
  height: 1.4rem;
  width: 1.4rem;
}
.menu-toggle-icon svg {
  height: 2rem;
  width: 2rem;
  margin: 0;
  align-items: center;
  justify-content: center;
  color: white;
}
.close-notifications-icon svg {
  height: 1rem;
  width: 1rem;
}
.left-top-text-control {
  overflow-x: scroll;
  scrollbar-width: none;
}
.left-top-text-control::-webkit-scrollbar {
  display: none;
}
.close-notification-container {
  display: flex;
  justify-content: right;
  height: 1.4rem !important;
}
.user-n-p-i-s {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1.2rem;
}
.user-n-p-i-s a {
  text-decoration: none;
}
.user-n-p-i-s svg {
  height: 1.5rem;
  width: 1.5rem;
}
.n-p-d-i-hidden {
  display: none;
}
.phone-number-section-n-p {
  display: grid;
  place-items: center !important;
  grid-template-columns: auto 4.2rem;
  background: var(--color-light);
  border-radius: var(--border-radius);
  padding: var(--search-padding);
  margin-top: 2px;
}
.phone-number-section-n-p input[type="text"] {
  background: transparent;
  width: 100%;
  margin-left: 2px;
  font-size: 0.9rem;
  color: var(--color-dark);
}
.phone-number-section-n-p button {
  background: var(--color-primary);
  height: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  color: white;
  border-radius: 12px;
}
.p-n-response-message-n-s {
  height: 1rem !important;
  color: var(--color-primary);
}
.phone-number-s-container-n {
  display: block !important;
}
.saved-posts-i-c-s {
  display: flex;
  flex-direction: column;
  gap: 1px !important;
}
.n-saved-p-h-s {
  font-weight: 600;
}
.u-n-saved-p-l-s {
  color: var(--color-primary);
}
.u-n-saved-p-l-s:hover {
  text-decoration: underline;
}
