.friends-links-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.friends-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
.friends-link-s {
  text-decoration: none;
  cursor: pointer;
}
.friends-link-text {
  margin: 0;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.friends-link-text svg {
  width: 24px;
  height: 24px;
  margin-bottom: 3px;
}
.friends-p-h-f {
  color: var(--color-gray);
  margin: 1rem 0;
}
.friends-t-h-s {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  position: relative;
}
.n-friends-a-a-p-s {
  position: absolute;
  bottom: -36px;
}
.friends-t-h-s h4 {
  color: var(--color-gray);
}
.friends-t-h-s a {
  color: var(--color-primary);
}
.friends-t-h-s a:hover {
  text-decoration: underline;
}
.friends-card-wrapper-s {
  display: grid;
  grid-template-columns: 1fr;
  gap: 58px;
  margin-bottom: 58px;
}
.friends-card-c-s {
  position: relative;
}
.friends-card-c-s img {
  width: 100%;
  border-radius: var(--card-border-radius);
  object-fit: cover;
  height: 350px;
  border: 1px solid var(--color-dark);
  transition: all 0.5s;
}
.friend-card-d-w {
  display: flex;
  justify-content: center;
}
.friend-card-d-c {
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 80%;
  position: absolute;
  bottom: -44px;
}
.friend-c-w-i {
  padding: 0 10px;
}
.friends-c-b-c-s {
  display: flex;
  gap: 10px;
}
.friends-c-b-c-s button {
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 12px;
}
.friends-c-b-c-s a {
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 12px;
  background-color: rgb(240, 240, 240);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.friend-card-n-d-s {
  display: flex;
  text-align: center;
  flex-direction: column;
  line-height: 18px;
  margin-bottom: 10px;
  font-weight: 500;
}
.friend-c-w-i img:hover {
  transform: scale(0.97);
}
