.cursor-pointer-element-style {
  cursor: pointer;
}
.zero-margin-top-i-s {
  margin-top: 0 !important;
}
.need-help-r-p-text-s {
  text-align: right;
  color: black;
  font-size: 0.88rem;
  display: block;
}
.extras-options-home-page {
  display: none;
}
.extras-options-circle-home-page {
  display: none;
}
.extras-options-circle-chat-page {
  display: none;
}
.extras-options-circle-error-page {
  display: none;
}
