/* ==================RIGHT================== */
.main .element_container .right {
  height: max-content;
  position: sticky;
  top: var(--sticky-top-right);
  bottom: 0;
}

.right .messages {
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
}

.right .messages .heading_messages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.right .messages i {
  font-size: 1.4rem;
}

.right .messages .search-bar {
  display: flex;
  margin-bottom: 1rem;
}

.right .messages .category {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.right .messages .category h6 {
  width: 100%;
  text-align: center;
  border-bottom: 4px solid var(--color-light);
  padding-bottom: 0.5rem;
  font-size: 0.85rem;
  cursor: pointer;
}

.right .messages .category h6:hover {
  color: var(--color-primary);
}

.right .messages .category .activeitem {
  border-color: var(--color-dark);
}

.right .messages .message {
  display: flex;
  align-items: start;
  gap: 1rem;
  margin-bottom: 1rem;
}

.right .messages .message:last-child {
  margin: 0;
}

.right .messages .message p {
  font-size: 0.8rem;
}

.right .messages .message .profile-photo {
  position: relative;
  overflow: visible;
}

.right .messages .message .profile-photo img {
  border-radius: 50%;
}

.right .messages .message .profile-photo .activeitem {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0.8rem;
  height: 0.8rem;
  background: var(--color-success);
  border: 3px solid var(--color-white);
  border-radius: 50%;
}

/* ---------------------FRIEND REUQESTS------------------- */
.right .friend-requests {
  margin-top: 1rem;
}

.right .friend-requests h4 {
  color: var(--color-gray);
  margin: 1rem 0;
}

.right .request {
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  margin-bottom: 0.7rem;
}

.right .request .info {
  display: flex;
  align-items: start;
  gap: 1rem;
  margin-bottom: 1rem;
}

.right .request .action {
  display: flex;
  gap: 1rem;
}
.right-section-icon {
  width: 1.6rem;
  height: 1.6rem;
}
.message-home-view-more {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: var(--color-primary) !important;
  font-size: 0.95rem;
}
.message-home-view-more:hover {
  color: var(--color-dark) !important;
}

.message-truncation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.centered-action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.r-s-i-c-pointer {
  cursor: pointer;
}
.btn-p-shrinker {
  padding: 0.6rem 1.5rem;
}
.w-o-c-r-s {
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.right-images-slider-container {
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  margin-top: 1rem;
}
.right-images-slider {
  width: 100%;
  height: 220px;
  min-height: 200px;
  position: relative;
  border-radius: var(--card-border-radius);
  overflow: hidden;
  background: var(--color-light);
}
.list-right-s .useritem {
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
  opacity: 0;
}
.list-right-s .useritem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--card-border-radius);
}
.list-right-s .useritem.useractivei {
  opacity: 1;
  z-index: 10;
}
.right-userthumbnail {
  position: absolute;
  bottom: 6px;
  z-index: 11;
  display: flex;
  gap: 10px;
  width: 100%;
  height: 40px;
  padding: 0 50px;
  box-sizing: border-box;
  overflow: auto;
  justify-content: center;
}
.right-userthumbnail::-webkit-scrollbar {
  width: 0;
}
.right-userthumbnail .useritem {
  width: 30px;
  height: 30px;
  filter: brightness(0.5);
  transition: 0.5s;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.right-userthumbnail .useritem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.right-userthumbnail .useritem.useractivei {
  filter: brightness(1.5);
  border: 1px solid var(--color-primary);
}

/* --------------------------------- */
.right-userthumbnail::-webkit-scrollbar {
  display: none !important;
}
.right-userthumbnail {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
.userarrows-r {
  top: 10px;
  position: absolute;
  right: 10px;
  z-index: 100;
  display: flex;
  gap: 10px;
}
.userarrows-r button {
  background: none;
  border-radius: 8px;
}
.userarrows-r button svg {
  width: 1.3rem;
  height: 1.3rem;
  color: var(--color-dark);
}
.right-i-p-c-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.65rem;
}
.right-i-p-c-heading h4:hover {
  color: var(--color-primary);
}
.right-i-p-c-heading svg {
  height: 1.4rem;
  width: 1.4rem;
}
.advertisement-right-cntainer {
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  margin-top: 1rem;
}
.advertisement-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.advertisement-heading i svg {
  height: 1.4rem;
  width: 1.4rem;
}
.right-heading-hover-s:hover {
  color: var(--color-primary);
}
.advertisement-photo-c-s {
  border-radius: var(--card-border-radius);
  overflow: hidden;
  margin-bottom: 0.3rem;
  max-width: 100%;
  max-height: 250px;
  min-height: 100px;
  background-color: var(--color-light);
}
.advertisement-photo-c-s img {
  border-radius: var(--card-border-radius);
  object-fit: cover;
  width: 100%;
  transition: all 0.45s;
}
.advertisement-photo-c-s img:hover {
  transform: scale(1.1);
}
.shop-now-r-btn-c svg {
  height: 1.3rem;
  width: 1.3rem;
}
.shop-now-r-btn-c button {
  margin-top: 0.6rem;
  color: white;
  padding: var(--btn-padding);
  width: 100%;
  background: var(--color-primary);
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.45s;
}
.shop-now-r-btn-c button:hover {
  transform: scale(0.95);
}
.ad-c-t-r-s {
  line-height: 1;
}
