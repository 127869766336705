.pictureBox-a-s {
  overflow: hidden;
}
.pictureBox {
  max-height: 400px;
  scrollbar-width: none;
  overflow: scroll;
  padding: 2px;
}
.pictureBox::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.p-update-picture-b {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: var(--btn-padding);
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  transition: all 100ms ease;
  border: 1px solid var(--color-primary);
}

.p-update-picture-b:hover {
  font-weight: 600;
  border: 2px solid var(--color-primary);
}
.update-p-p-p-s-c {
  max-width: 300px;
  display: inline-block;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.update-picture-wrap {
  padding: 10px 25px;
}
.update-picture-buttons button {
  width: 100%;
  justify-content: center;
}
.p-blue_btn {
  border: none;
  outline: none;
  background: var(--color-primary);
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  transform: translateY(-1px);
}
.update-image-desc {
  padding: 15px 15px 0 15px;
}
.textarea-u-p-p {
  width: 100%;
  resize: none;
  height: 50px;
  border-radius: 12px;
  font-family: inherit;
  color: var(--color-dark);
}
.update-image-desc textarea {
  background: var(--color-light);
  width: 100%;
  color: var(--color-dark);
  padding: 8px;
}
.update-image-desc textarea::placeholder {
  color: var(--color-gray);
}
.textarea-u-p-p:focus {
  height: 55px;
}
.textarea-u-p-p:hover {
  height: 55px;
}
.update-center {
  position: relative;
  height: 340px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crooper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 300px;
  width: 100%;
}
.crooper img {
  width: 100%;
  object-fit: contain !important;
  border-radius: 8px !important;
}
.update-img .reactEasyCrop_CropArea {
  width: 300px !important;
  height: 300px !important;
  color: rgba(0, 0, 0, 0.5);
}
.slider {
  position: absolute;
  bottom: -12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.slider input[type="range"] {
  -webkit-appearance: none;
  width: 55%;
  height: 4px;
  background: var(--color-dark);
}
.slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  outline: none;
  border-radius: 50%;
  background: var(--color-primary);
  cursor: pointer;
}
.slider input[type="range"]::-webkit-slider-thumb:hover {
  border: 1px solid var(--color-dark);
}
.slider_circle {
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.flex-up {
  position: absolute;
  bottom: 1rem;
  left: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 0.5rem;
}
.gray-btn-p {
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 0.4rem 0.5rem;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  transition: all 100ms ease;
  border: 1px solid var(--color-primary);
}
.update-submit-wrap {
  display: flex;
  justify-content: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  gap: 1rem;
  margin-top: 8px;
}
.blue-link {
  background-color: var(--color-light);
  padding: 0.45rem 0.8rem;
  border-radius: 10px;
  border: 1px solid var(--color-dark);
  cursor: pointer;
}
.old-pictures-wrap {
  padding: 15px;
  overflow: auto;
  max-height: 400px;
}
.old-pictures-wrap h4 {
  padding-bottom: 10px;
  font-size: 1.2rem;
}
.old-pictures {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}
.other-pic-p-t-s {
  padding-top: 10px;
}
.old-pictures img {
  height: 100px;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
}
.old-pictures img:hover {
  box-shadow: 0 0 0.4rem var(--color-primary);
}
@media (max-width: 330px) {
  .old-pictures-wrap-h-c-s {
    max-height: 320px;
  }
}
.u-p-p-a-b-i-s svg {
  width: 1.4rem;
  height: 1.4rem;
  transform: translateY(3px);
}
.crop-i-c-s {
  display: flex;
  align-items: center;
  justify-content: center;
}
.b-i-s-s-u-p-p svg {
  width: 1.1rem;
  height: 1.1rem;
}
.plus-icon-p-p-s svg {
  width: 1.1rem;
  height: 1.1rem;
  transform: translateY(2px);
}
