/* ---------------------FEEDS------------------- */
.middle .feeds .feed {
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  margin: 1rem 0;
  font-size: 0.85rem;
  line-height: 1.5rem;
}

.middle .feed .head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.middle .feed .head .user {
  display: flex;
  align-items: start;
  gap: 1rem;
}

.middle .feed .caption {
  padding: 0 1rem;
  font-size: 0.9rem;
  line-height: 1.3rem;
}

.middle .feed .hash-tag {
  color: var(--color-primary);
  cursor: pointer;
}

.middle .feed .hash-tag:hover {
  text-decoration: underline;
}

.middle .feed .photo {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}

.middle .feed .photo img {
  border-radius: var(--card-border-radius);
  object-fit: cover;
}

.middle .feed .action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  margin: 0.6rem;
}

.middle .feed .action-buttons > div {
  display: flex;
  gap: 0.6rem;
}

.middle .feed .like img {
  width: 1.5rem;
  height: 1.5rem;
}

.middle .feed .liked-by {
  display: flex;
}

.middle .feed .liked-by span {
  width: 1.4rem;
  height: 1.4rem;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--color-white);
  margin-left: -0.6rem;
}

.middle .feed .liked-by span img {
  width: 1.4rem;
  height: 1.4rem;
  object-fit: cover;
}

.middle .feed .liked-by span:first-child {
  margin: 0;
}

.middle .feed .liked-by p {
  margin-left: 0.5rem;
}

.middle .feed .action-buttons span,
.middle .feed .show-comments {
  cursor: pointer;
}

.middle .feed .action-buttons span:hover,
.middle .feed .show-comments:hover {
  color: var(--color-primary);
}
.spacing-for-comments {
  margin-top: 2px;
}
.user-feed-icons svg {
  width: 1.5rem;
  height: 1.5rem;
}
.more svg {
  cursor: pointer;
}
.c-p-user-feed-post {
  cursor: pointer;
}
.profile-photo img {
  object-fit: cover;
}
.c-p-user-feed-post {
  display: flex;
  align-items: center;
}
.c-p-user-feed-post span {
  width: 0.95rem;
  height: 0.95rem;
}
.c-p-user-feed-post span:hover {
  color: var(--color-primary);
}
.refresh-icon-u-f-s svg {
  width: 0.95rem;
  height: 0.95rem;
}
.more:hover {
  color: var(--color-primary);
}
.bg-post-image-container {
  position: relative;
}
.bg-post-image-container img {
  object-fit: cover;
}
.post-bg-text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
  width: 100%;
}
/*--------------------------------------------------- */
/*--------------------------------------------------- */
/*--------------------------------------------------- */
.u-explore-search-menu-container {
  position: fixed;
  top: calc(50% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 400px;
  max-height: 428px;
  width: 500px;
  border-radius: 10px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  overflow: hidden;
}
@media (max-width: 550px) {
  .u-explore-search-menu-container {
    width: 94%;
  }
}
.u-explore-search-bar-s-container {
  background: var(--color-white);
  width: 100%;
  height: 100%;
  min-height: 1.3rem;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 2rem auto 6rem;
  padding: var(--search-padding);
  box-shadow: 0 0 0.6rem var(--color-primary);
}
.explore-s-search-icon-s {
  width: 1.2rem;
  height: 1.2rem;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.explore-s-search-icon-s svg {
  max-width: 1.2rem;
  max-height: 1.2rem;
}
.u-explore-search-bar-s-container input[type="search"] {
  background: transparent;
  width: 100%;
  margin-left: 1rem;
  font-size: 0.9rem;
  color: var(--color-dark);
}
.u-explore-search-bar-s-container input[type="text"] {
  background: transparent;
  width: 100%;
  margin-left: 1rem;
  font-size: 0.9rem;
  color: var(--color-dark);
}
.u-explore-search-bar-s-container select {
  background: transparent;
  height: 100%;
  padding-left: 0.5rem;
  margin-left: 1rem;
  text-align: center;
  color: var(--color-dark);
  border-left: 2px solid var(--color-white);
}
/*--------------------------------------------------- */
.u-explore-search-users-list-container {
  background: var(--color-white);
  box-shadow: 0 0 0.6rem var(--color-primary) !important;
  width: 100%;
  height: 100%;
  min-height: 340px;
  max-height: 360px;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
}
.u-eplore-s-s-content-c {
  max-height: 350px;
  overflow: scroll;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
/*--------------------------------------------------- */
/*--------------------------------------------------- */
/*--------------------------------------------------- */
.no-posts-y-u-f {
  color: var(--color-gray);
  margin: 1rem 0;
}
.u-f-m-loader-s {
  display: grid;
  place-items: center;
  margin: 20px 0;
}
.skeleton-middle-u-f-d-p-p-s {
  width: 2.7rem;
  height: 2.7rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
}
.caption-p-t-l-t-c {
  max-width: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.r-s-l-p-p-s {
  max-height: 260px;
  max-width: 100%;
  overflow: hidden !important;
  border-radius: var(--card-border-radius);
}
.s-l-m-action-buttons {
  max-width: 98%;
}

.middle .feed .react-s-l-liked-by-s .liked-by {
  display: flex;
  align-items: center !important;
  max-width: 98%;
}

.middle .feed .react-s-l-liked-by-s .liked-by span {
  width: 2rem;
  height: 2rem;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--color-white);
  margin-left: -0.6rem;
}

.middle .feed .react-s-l-liked-by-s .liked-by span img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
}

.middle .feed .react-s-l-liked-by-s .liked-by span:first-child {
  margin: 0;
}
.r-l-l-b-c-s {
  display: flex;
  align-items: center;
  gap: 8px;
}

.middle .feed .caption p {
  word-break: break-word;
}
