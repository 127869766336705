.reset {
  background: var(--bg-secondary);
  height: 100vh;
  color: var(--color-primary);
}
.reset_header {
  background: var(--bg-primary);
  box-shadow: 1px 8px 15px -7px var(--shadow-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 50px;
}
.reset_header img {
  width: 160px;
}
.right_reset {
  display: flex;
  align-items: center;
  gap: 10px;
}
.right_reset img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.reset_wrap {
  height: 67vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.reset_form {
  position: relative;
  background: var(--bg-primary);
  box-shadow: 0 1px 2px var(--shadow-1);
  border-radius: 10px;
  width: 100%;
  height: fit-content !important;
}

.reset_form_header {
  padding: 15px;
  font-weight: 600;
  font-size: 24px;
  border-bottom: 1px solid var(--bg-third);
}
.reset_form_text {
  padding: 15px;
  font-size: 17px;
}
.reset_form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
.reset_form_btns {
  display: flex;
  align-self: flex-end;
  gap: 10px;
  margin-top: 5px;
  padding: 10px;
  position: relative;
  width: 100%;
  padding-left: 65%;
  border-top: 1px solid var(--bg-third);
}
.reset_form_btns a,
.reset_form_btns button {
  width: 90px;
}
.reset_grid {
  display: grid;
  grid-template-columns: 1fr;
  font-size: 15px;
  color: var(--color-secondary);
}
.reset_left {
  padding: 15px;
}
.reset_grid label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px;
}
.reset_left input {
  width: 20px;
  height: 20px;
}
.label_col {
  display: flex;
  flex-direction: column;
}
.reset_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reset_right img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.dynamic_height {
  height: 430px;
}
.conf_password_error {
  transform: translateY(62px) !important;
}
.conf_password_error .error_arrow_left {
  transform: rotate(180deg) translateY(65px);
}
.err_res_password {
  transform: translateY(-16px) !important;
}
.err_res_password .error_arrow_left {
  transform: translateY(19px);
}
/*----Queries----*/
@media (max-width: 350px) {
  .reset_form {
    height: fit-content;
  }

  .reset_form form input {
    width: 290px;
  }
  .reset_form .input_error {
    width: 290px;
  }
  .reset_form .error_icon {
    right: 20px;
  }
  .reset_form_btns {
    padding-left: 40% !important;
  }
}
@media (max-width: 450px) {
  .reset_form_btns {
    padding-left: 50%;
  }
}
@media (min-width: 650px) {
  .reset_form {
    width: 570px;
  }
  .reset_grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .dynamic_height {
    height: 330px;
  }
  .reset_form_btns {
    padding-left: 65%;
  }
}

@media (min-width: 850px) and (max-width: 1050px) {
  .reset_form .input_error {
    left: 0;
    top: -60px;
    width: 100%;
  }
  .reset_form .error_arrow_left {
    border-top: 10px solid #b94a48;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    top: 45px;
    left: 10px;
    width: 10px;
  }
}
@media (min-width: 1050px) {
  .reset_form .input_error_desktop {
    width: 320px;
    left: -92%;
  }
}
/*----Queries----*/
/*---------------------*/
/*---------------------*/
/*---------------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

@media screen and (min-width: 1024px) {
  .register_page {
    --h2-font-size: 1.75rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
.register_page {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.register_page {
  background-color: hsl(252, 30%, 95%);
  font-family: var(--body-font);
  color: var(--text-color);
}
input,
button {
  font-family: var(--body-font);
  border: none;
  outline: none;
}
button {
  cursor: pointer;
}
img {
  max-width: 100%;
  height: auto;
}
.login__content,
.login__form,
.login__inputs {
  display: grid;
}
.login__content {
  position: relative;
  height: 100vh;
  align-items: center;
}
.login__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.login__form {
  position: relative;
  background-color: hsla(244, 16%, 92%, 0.6);
  border: 2px solid hsla(244, 16%, 92%, 0.75);
  margin-inline: 1.5rem;
  row-gap: 1.25rem;
  backdrop-filter: blur(20px);
  padding: 1.2rem;
  border-radius: 1rem;
}
.login__title {
  color: var(--title-color);
  font-size: var(--h2-font-size);
  margin-bottom: 0.1rem;
}
.login__title span {
  color: var(--first-color);
}
.login__description {
  font-size: var(--small-font-size);
}
.login__inputs {
  row-gap: 0.75rem;
  margin-bottom: 0.5rem;
}
.login__label {
  display: block;
  color: var(--title-color);
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: 0.2rem;
}
.login__input {
  width: 100%;
  padding: 8px 10px;
  border-radius: 6px;
  border: 2px solid var(--text-color);
  background-color: hsla(244, 16%, 92%, 0.6);
  color: var(--title-color);
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
  transition: border 0.4s;
}
.login__input::placeholder {
  color: var(--text-color);
}
.login__input:focus,
.login__input:valid {
  border: 2px solid var(--first-color);
}
.login__box {
  position: relative;
}
.login__box .login__input {
  padding-right: 36px;
}
.login__eye {
  width: max-content;
  height: max-content;
  position: absolute;
  right: 0.75rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
  font-size: 1.25rem;
  cursor: pointer;
}
.login__check-label {
  position: relative;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  font-size: var(--small-font-size);
}
.login__check-icon {
  position: absolute;
  display: none;
  font-size: 16px;
}
.login__check-input {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--text-color);
  background-color: hsla(244, 16%, 92%, 0.2);
  border-radius: 0.25rem;
  cursor: pointer;
}
.login__check-input:checked {
  background: var(--first-color);
}
.login__check-input:checked + .login__check-icon {
  display: block;
  color: #fff;
}
.login__buttons {
  display: flex;
  column-gap: 0.75rem;
}
.login__button {
  width: 100%;
  padding: 11px 2rem;
  border-radius: 6px;
  background: linear-gradient(180deg, var(--first-color), var(--second-color));
  color: #fff;
  font-size: 1rem;
  font-weight: var(--font-semi-bold);
  box-shadow: 0 6px 24px hsla(244, 75%, 48%, 0.5);
  margin-bottom: 0.85rem;
}
.login__button-ghost {
  background: hsla(244, 16%, 92%, 0.6);
  border: 2px solid var(--first-color);
  color: var(--first-color);
  box-shadow: none;
}
.login__forgot {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--first-color);
  text-decoration: none;
}
.register__forgot {
  font-size: 0.85rem;
  font-weight: var(--font-semi-bold);
  color: var(--first-color);
  text-decoration: none;
}
@media screen and (max-width: 360px) {
  .login__buttons {
    flex-direction: row !important;
  }
}
@media screen and (min-width: 576px) {
  .login__form {
    width: 450px;
    justify-self: center;
  }
}
@media screen and (min-width: 1064px) {
  .container {
    height: 100vh;
    display: grid;
    place-items: center;
  }
  .login__content {
    width: 1024px;
    height: 600px;
  }
  .login__img {
    border-radius: 3.5rem;
    box-shadow: 0 24px 48px hsla(244, 75%, 36%, 0.45);
  }
  .login__form {
    justify-self: flex-end;
    margin-right: 4.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .login__content {
    height: 700px;
  }
  .login__form {
    row-gap: 0.5rem;
    padding: 1.2rem;
    border-radius: 1.25rem;
    border: 2px solid hsla(244, 16%, 92%, 0.75);
  }
  .login__description,
  .login__label,
  .login__button {
    font-size: var(--normal-font-size);
  }
  .login__inputs {
    row-gap: 0.5rem;
    margin-bottom: 0.75rem;
  }
  .login__input {
    border: 2.5px solid var(--text-color);
    padding: 8px;
    font-size: var(--small-font-size);
  }
  .login__input:focus,
  .login__input:valid {
    border: 2.5px solid var(--first-color);
  }
  .login__button {
    padding-block: 1rem;
    margin-bottom: 0.85rem;
  }
  .login__button-ghost {
    border: 2.5px solid var(--first-color);
  }
}
.intro_to_signup {
  margin: 0;
  padding: 0;
}
.my_flexed_name_fields {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.user_name_input_field {
  padding: 0;
  margin: 0;
  width: 100%;
}
.user_field_container {
  margin-bottom: 0;
  padding-bottom: 0;
}
.login__check {
  margin-top: 6px;
}
.dob-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.dob-select {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  border: 2px solid var(--text-color);
  border-radius: 5px;
  height: 33px;
  padding: 0;
  margin: 0;
  width: calc(100% / 3);
  background-color: hsla(244, 16%, 92%, 0.6);
  color: var(--title-color);
}
.user_dob_option {
  background-color: hsla(244, 16%, 92%, 0.6);
}
.dob-gap {
  margin: 0 8px;
}
@media only screen and (max-width: 600px) {
  .dob-container {
    flex-direction: row;
  }
  .dob-gap {
    margin: 10px 0;
  }
}
.register_date_section {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gender_select_section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 20px;
}
.option-container {
  display: flex;
  justify-content: left;
  align-items: left;
  border: 2px solid var(--text-color);
  border-radius: 10px;
  padding: 5px 10px;
  margin: 0 0;
  width: calc(100% / 2);
  background-color: hsla(244, 16%, 92%, 0.6);
}
.gender_label {
  width: 70px;
  margin-left: 0;
  color: black;
}
.gender_radio_input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.register_button_preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.signup_button_spacing {
  margin-top: 6px;
}
.bottom-search-account-o {
  cursor: pointer;
}
.user-email-word-c {
  overflow-wrap: break-all;
  width: 300px;
}

.user-reset-img-p {
  width: 150px;
  height: 150px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}
.send-email-user-content-h {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
