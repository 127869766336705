.image-preview-b-s {
  font-family: Poppins;
  margin: 0;
  color: #eee;
}
.image-preview-b-s svg {
  width: 1.6rem;
  height: 1.6rem;
}
/* css slider */
.userslider {
  height: 100vh;
  position: relative;
}
.userslider .list .useritem {
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
  opacity: 0;
}
.userslider .list .useritem img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.userslider .list .useritem.useractivei {
  opacity: 1;
  z-index: 10;
}

.userarrows {
  position: absolute;
  top: 6%;
  right: 5%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(228, 223, 223, 0.45);
  border-radius: 10px;
}
.userarrows button {
  background: none;
  border: none;
  font-family: monospace;
  border-radius: 5px;
  font-size: x-large;
  color: black;
  transition: all 0.45s;
}
.userarrows button:hover {
  transform: scale(1.3);
}
.userthumbnail {
  position: absolute;
  bottom: 8px;
  z-index: 11;
  display: flex;
  gap: 10px;
  width: 100%;
  height: 150px;
  padding: 0 50px;
  box-sizing: border-box;
  overflow: auto;
  justify-content: start;
}
.userthumbnail::-webkit-scrollbar {
  width: 0;
}
.userthumbnail .useritem {
  width: 80px;
  height: 120px;
  filter: brightness(0.5);
  transition: 0.5s;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 12px;
}
.userthumbnail .useritem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.userthumbnail .useritem.useractivei {
  filter: brightness(1.4);
  border: 1px solid var(--color-primary);
}

@media screen and (max-width: 678px) {
  .userthumbnail {
    justify-content: start;
  }
  .userarrows {
    top: 5%;
  }
}

.userthumbnail::-webkit-scrollbar {
  display: none !important;
}
.userthumbnail {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
/* ------------- */
.otherusericonimg {
  position: absolute;
  top: 1%;
  right: 5%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(228, 223, 223, 0.45);
  border-radius: 10px;
}
.otherusericonimg button {
  background: none;
  border: none;
  font-family: monospace;
  border-radius: 5px;
  font-size: x-large;
  color: black;
  transition: all 0.45s;
}
.otherusericonimg button:hover {
  transform: scale(1.3);
}
.background-images-preview-texts-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.background-images-preview-texts {
  z-index: 100;
  position: fixed;
  top: 42%;
  width: 90%;
  font-weight: 500;
  font-size: 1.1rem;
  color: white;
}
@media screen and (min-width: 768px) {
  .background-images-preview-texts {
    top: 40%;
    width: 54%;
  }
}
.background-images-preview-texts:hover {
  color: var(--color-dark);
}
