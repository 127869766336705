/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */
@media screen and (max-width: 580px) {
  .extras-options .extras-options-home-page {
    display: flex !important;
  }
}
@media screen and (min-width: 580px) {
  .extras-options .extras-options-circle-home-page {
    display: flex !important;
  }
}
.extras-options .extras-options-circle-chat-page {
  display: flex !important;
}
.extras-options .extras-options-circle-chat-page .circle-user-menu-content {
  bottom: 180px;
}
.extras-options .extras-options-circle-error-page {
  display: flex !important;
}
