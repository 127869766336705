.error-page-error-icon-big {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-page-error-icon-big svg {
  width: 160px;
  height: 160px;
  animation: zoom-in-zoom-out 6s linear infinite;
}
.error-page-error-icon-big svg:hover {
  color: var(--color-primary);
}
.error-box-page-button {
  border: 0.4px solid transparent;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68%;
  color: white;
  background: var(--color-primary);
  transition: all 0.45s;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.error-box-page-button:hover {
  border: 0.4px solid var(--color-dark);
}
/* ------------------------------------------------ */
/* ------------------------------------------------ */
.error-box-page-button:hover {
  transform: translateY(-4px) translateX(-2px);
  box-shadow: 2px 5px 0 0 var(--color-dark);
}

.error-box-page-button:active {
  transform: translateY(2px) translateX(1px);
  box-shadow: 0 0 0 0 var(--color-dark);
}
/* ------------------------------------------------ */
/* ------------------------------------------------ */
/* ------------------------------------------------ */
.help-contact-us-submit-button-div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.help-contact-us-submit-button-div svg {
  width: 1.2rem;
  height: 1.2rem;
}
.error-page-error-top-r-m {
  background-color: red;
  border-radius: 10px;
  width: fit-content;
  max-width: 305px;
  padding: 0.4rem;
  font-weight: 420;
  color: white;
  position: fixed;
  top: 5.4rem;
  z-index: 10;
}
.error-page-popup-container-s {
  display: flex;
  justify-content: right;
}
