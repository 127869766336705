.u-card-t {
  position: relative;
  width: 350px;
  height: 190px;
  background: var(--color-white);
  border-radius: 20px;
  box-shadow: 0 35px 80px rgba(0, 0, 0, 0.38);
  transition: 0.5s;
}

.u-card-t:hover {
  height: 350px;
  margin-top: 4rem;
}

.u-t-imgBx {
  position: absolute;
  left: 50%;
  top: -24%;
  transform: translateX(-50%);
  width: 136px;
  height: 136px;
  background: var(--color-white);
  border-radius: 20px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  transition: 0.5s;
}

.u-card-t:hover .u-t-imgBx {
  width: 180px;
  height: 180px;
}

.u-t-imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ====================Content CSS============================== */

.u-card-t .u-t-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.u-card-t .u-t-content .u-t-details {
  padding: 40px;
  text-align: center;
  width: 100%;
  transition: 1s;
  transform: translateY(150px);
}

.u-card-t:hover .u-t-content .u-t-details {
  transform: translateY(0px);
}

.u-card-t .u-t-content .u-t-details h2 {
  font-size: 1.25em;
  font-weight: 600;
  color: var(--color-dark);
  line-height: 1.2em;
}

.u-card-t .u-t-content .u-t-details h2 span {
  font-size: 0.75em;
  font-weight: 500;
}

.u-card-t .u-t-content .u-t-details .u-t-data {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.u-card-t .u-t-content .u-t-details .u-t-data h3 {
  font-size: 1em;
  color: var(--color-gray);
  line-height: 1.2em;
  font-weight: 600;
}

.u-card-t .u-t-content .u-t-details .u-t-data h3 span {
  font-size: 0.85em;
  font-weight: 400;
  color: var(--color-dark);
}

.u-card-t .u-t-content .u-t-details .u-t-actionBtn {
  display: flex;
  justify-content: space-between;
  gap: 14px;
}

.u-card-t .u-t-content .u-t-details .u-t-actionBtn .u-t-c-f-a-b-s {
  padding: 10px 30px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 1em;
  font-weight: 500;
  background: var(--color-primary);
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  width: 100%;
}

.u-card-t .u-t-content .u-t-details .u-t-actionBtn .u-t-c-f-a-b-s:nth-child(2) {
  border: 1px solid #5c5c5c;
  color: var(--color-dark);
  background: var(--color-light);
  transition: 0.3s;
}

.job-p-u-t-d {
  color: var(--color-gray);
}
