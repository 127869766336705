.chat-m-page-c-c-s {
  display: grid;
  height: 100vh;
  max-height: 100vh;
}
@media (min-width: 1024px) {
  .chat-m-page-c-c-s {
    grid-template-columns: 320px 1fr;
  }
}
.chat-sidebar-container-s {
  background: var(--color-white);
}
.chat-sidebar-content-s {
  background: var(--color-light);
  display: flex;
  padding-top: 0.6rem;
  padding-bottom: 0.85rem;
  flex-direction: column;
  justify-content: space-between;
  border-top-right-radius: 0.55rem;
  border-bottom-right-radius: 0.55rem;
  width: 3rem;
  height: 100%;
  z-index: 1;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
}
.user-chat-sidebar-s {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 48px 1fr;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
}
.messages-chat-p-i {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}
.messages-chat-p-i svg {
  width: 1.5rem;
  height: 1.5rem;
}
.messages-chat-p-i:hover {
  background: var(--color-white);
  border-radius: 0;
}
.messages-chat-p-i-active {
  background: var(--color-white);
  border-radius: 0;
}
.messages-chat-p-u-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}
.messages-chat-p-u-img img {
  width: 72%;
  height: 72%;
  object-fit: cover;
  border-radius: 50%;
  border: 1.5px solid var(--color-primary);
}
.messages-chat-p-u-img img:hover {
  border: 1.5px solid var(--color-dark);
}
.hidden-messages-component {
  display: none !important;
}
@media (min-width: 1024px) {
  .chat-sidebar-container-s-d-p {
    display: block !important;
  }
}
.messages-h-u-u-s {
  margin-top: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.messages-home-u-d-s {
  display: none;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1024px) {
  .messages-home-u-d-s-lg {
    display: flex;
  }
}
.messages-logo-icon-h {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  font-weight: 500;
  gap: 10px;
}
.messages-logo-icon-h span {
  font-weight: 600;
  color: var(--color-primary);
}
.messages-logo-icon-h svg {
  width: 4rem;
  height: 4rem;
}
.p-chat-sidebar-content-s {
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}
.chat-siebar-messages-heading-s-c {
  height: 4rem;
}
.chat-siebar-messages-heading-s {
  font-size: 1.3rem;
  line-height: 1.75rem;
  font-weight: 700;
  padding: 1.2rem 1rem 1rem 1rem;
}
.chat-s-divider-s {
  margin: 0.25rem 0.4rem 0.25rem 0.45rem;
  padding: 0.5px;
  background-color: var(--color-gray);
  border-radius: 8px;
}
.chat-a-m-c-c-s {
  height: calc(100vh - 88px);
  overflow-x: scroll;
  overflow-y: scroll;
  padding: 8px;
}
.s-s-c-m-p-p-s {
  text-align: center;
  margin: 0.8rem 0;
  font-weight: 500;
  font-size: 1.3rem;
}
.s-s-c-m-p-p-s span {
  color: var(--color-primary);
  font-weight: 560;
}
.e-u-s-c-m-p-s {
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
}
.c-p-arrow-u-l-s-c {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 1.25rem;
}
.c-p-arrow-u-l-s-c svg {
  height: 4.5rem;
  width: 4.5rem;
}
@media (min-width: 1024px) {
  .s-s-c-m-p-p-s {
    display: none;
  }
}
.search-u-m-p-c-s {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 228px;
  width: 500px;
  border-radius: 10px;
}
@media (max-width: 550px) {
  .search-u-m-p-c-s {
    width: 94%;
  }
}
.m-p-search-container-s {
  background: var(--color-white);
  margin-bottom: 1.1rem;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 2rem auto 6rem;
  padding: 8px;
  box-shadow: 0 0 0.1rem var(--color-primary);
}
.m-p-search-container-s input[type="search"] {
  background: transparent;
  width: 100%;
  margin-left: 1rem;
  font-size: 0.9rem;
  color: var(--color-dark);
}
.m-p-search-container-s select {
  background: transparent;
  height: 100%;
  padding-left: 0.5rem;
  margin-left: 1rem;
  text-align: center;
  color: var(--color-dark);
  border-left: 2px solid var(--color-white);
}

.m-p-search-results-c-s {
  background: var(--color-white);
  height: 300px;
  border-radius: 10px;
  padding: 0.8rem;
  box-shadow: 0 0 0.1rem var(--color-primary);
}
.u-m-p-search-i-s svg {
  width: 1.4rem;
  height: 1.4rem;
}
.searched-contents-m-p-s {
  border-radius: 10px;
  height: 100%;
  max-height: 100%;
  overflow: scroll;
}
.chat-friends-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-white);
  box-shadow: 0 0 1rem var(--color-primary);
  min-height: 228px;
  width: 500px;
  border-radius: 10px;
  padding: 0.4rem;
}
@media (max-width: 550px) {
  .chat-friends-box {
    width: 96%;
  }
}
.chat-friends-content-c {
  height: 460px;
  max-height: 480px;
  max-width: 100%;
  margin: 0.5rem;
  border-radius: 10px;
  overflow: scroll;
}
.user-chat-friends-title {
  font-size: 1.3rem;
  font-weight: 540;
}
.user-chat-friends-title span {
  color: var(--color-primary);
}
.user-chat-friends-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 10px;
}
.user-chat-friends-l-item {
  cursor: pointer;
  border-radius: var(--card-border-radius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-chat-friends-l-item img {
  width: 86%;
  object-fit: cover;
  border-radius: var(--card-border-radius);
  border-radius: 50% !important;
  transition: all 0.5s;
}
.user-chat-friends-l-item img:hover {
  filter: brightness(80%);
}
.user-chat-friends-title-container-s {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  padding: 0 8px;
}
.user-chat-friends-title-container-s svg {
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
}
.user-chat-p-p-friends-name {
  font-weight: 460;
  font-size: 1.1rem;
  text-align: center;
}
.user-chat-p-p-friends-name:hover {
  color: var(--color-primary);
}
.user-friend-s-p-chat-div {
  display: flex;
  justify-content: center;
  position: relative;
}
.user-friend-online-c-s {
  width: 12px;
  height: 12px;
  background-color: var(--color-primary);
  border-radius: 50%;
  position: absolute;
  top: 10%;
  right: 18%;
}
.messages-page-header-s {
  position: sticky;
  top: 0;
  height: 4.3rem;
  background: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.messages-page-user-profile-i {
  height: 3.4rem;
  width: 3.4rem;
  border-radius: 50%;
}
.messages-p-u-online-s {
  color: var(--color-primary);
}
.m-p-h-d-style {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 1024px) {
  .m-p-h-d-style {
    gap: 0.85rem;
  }
}
.messages-p-u-n-s-d-s h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.messages-p-u-n-s-d-s p {
  margin-top: -0.1rem;
  margin-bottom: -0.5rem;
}
/* ------------------------------------------- */
.msg-p-u-t-u-check-i-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  top: 0;
  right: 26px;
}
.msg-p-u-t-u-check-i svg {
  width: 1.3rem !important;
  width: 1.3rem !important;
  color: var(--color-primary);
}
/* ------------------------------------------- */
/* ------------------------------------------- */
/* ------------------------------------------- */
.m-p-t-d-s-i {
  cursor: pointer;
  position: relative;
}
.m-p-t-d-s-i svg {
  height: 1.55rem;
  width: 1.55rem;
}
.m-p-t-d-s-i svg:hover {
  color: var(--color-primary);
}
.three-dots-vertical-m-p-l {
  position: absolute;
  background-color: var(--color-white);
  box-shadow: 0 0 0.5rem var(--color-primary);
  border-radius: 10px;
  padding: 0.5rem;
  width: 200px;
  right: 2px;
  bottom: -110px;
  z-index: 1;
}
.options-message-page-data-user {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  padding: 6.5px 6px;
}
.options-message-page-data-user:hover {
  background-color: var(--color-light);
}
.t-d-msg-pg-u-i svg {
  width: 1.2rem;
  height: 1.2rem;
}
/* ------------------------------------------- */
.three-dots-vertical-m-p-l-umsg {
  position: absolute;
  background-color: var(--color-white);
  box-shadow: 0 0 0.5rem var(--color-primary);
  border-radius: 10px;
  padding: 0.5rem;
  width: 260px;
  right: 2px;
  bottom: -92px;
}
.three-dots-vertical-m-p-l-umsg-error {
  position: absolute;
  background-color: var(--color-white);
  box-shadow: 0 0 0.5rem var(--color-primary);
  border-radius: 10px;
  padding: 0.5rem;
  width: 260px;
  right: 2px;
  bottom: -140px;
}
/* ------------------------------------------- */
/* ------------------------------------------- */
/* ------------------------------------------- */
@media (min-width: 1024px) {
  .previous-c-p-i-s {
    display: none;
  }
}

.previous-c-p-i-s svg {
  height: 1.55rem;
  width: 1.55rem;
}
.show-all-messages-m-p {
  height: calc(100vh - 128px);
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.send-message-container {
  height: 4rem;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.attachment-send-m-p svg {
  height: 1.55rem;
  width: 1.55rem;
}
.s-m-c-c-m-p-s-o-c {
  position: relative;
}
.s-m-c-c-m-p-s {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
}
.s-m-c-c-m-p-s:hover {
  background-color: var(--color-primary);
}
.s-m-c-c-m-p-s:hover svg {
  color: white;
}
.s-m-c-c-m-p-s button {
  background: none;
}
.v-a-i-m-p-c {
  background: var(--color-white);
  box-shadow: 0 0 0.5rem var(--color-primary);
  border-radius: 10px;
  position: absolute;
  bottom: 3.5rem;
  width: 9rem;
  padding: 0.5rem;
}
.m-p-a-u-o-s svg {
  height: 1.5rem;
  width: 1.5rem;
}
.s-i-o-v-l-t-s {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.75rem;
  cursor: pointer;
  border-radius: 10px;
}
.s-i-o-v-l-t-s:hover {
  background: var(--color-light);
  font-weight: 500;
}
.upload-file-display-c {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  overflow: hidden;
  position: sticky;
  bottom: 0;
}
.upload-file-u-f-c-s {
  padding: 0.75rem;
  background: var(--color-white);
  border-radius: 10px;
}
.upload-image-s-a-m {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 17rem;
  max-height: 19rem;
  border-radius: 10px;
  object-fit: scale-down;
}
.m-p-c-i-s {
  width: fit-content;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.m-p-c-i-s:hover {
  color: var(--color-primary);
}
.m-p-c-i-s svg {
  width: 1.6rem;
  height: 1.6rem;
}
.upload-video-i-t-m-p {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 17rem;
  max-height: 19rem;
  border-radius: 10px;
  object-fit: scale-down;
}
.upload-file-m-p-loading-c {
  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.messages-page-container-s {
  width: 100%;
  height: 100%;
  --bg: linear-gradient(
      45deg,
      var(--color-light) 25%,
      transparent 25%,
      transparent 75%,
      var(--color-light) 75%,
      var(--color-light)
    ),
    linear-gradient(
      45deg,
      var(--color-light) 25%,
      var(--color-white) 25%,
      var(--color-white) 75%,
      var(--color-light) 75%,
      var(--color-light)
    );
  --bgsize: 60px 60px;
  --bgposition: 0 0, 30px 30px;
  background-color: var(--color-white);
  background-image: var(--bg);
  background-size: var(--bgsize);
  background-position: var(--bgposition);
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  overflow: hidden;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
}

.send-message-m-p-i-s {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  outline-style: none;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  color: var(--color-dark);
}
.send-message-m-p-i-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.send-message-m-p-button-s {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 4px;
  cursor: pointer;
}
.send-message-m-p-button-s:hover svg {
  color: var(--color-primary);
}
.send-message-m-p-button-s svg {
  width: 1.6rem;
  height: 1.6rem;
  color: var(--color-dark);
}
.chat-add-emoji-icon-m-p {
  cursor: pointer;
  position: relative;
}
.chat-add-emoji-icon-m-p svg {
  width: 1.6rem;
  height: 1.6rem;
  transform: translateY(3px);
}
.send-msg-emoji-picker .emoji-picker-react {
  position: absolute;
  bottom: 3.2rem;
  height: 280px;
  right: -35px;
}
.r-s-m-p-s-m-i-c-i-s {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.message-r-c-a-m-s-s {
  background-color: var(--color-white);
  box-shadow: 0 0 0.2rem var(--color-primary);
  padding: 0.25rem;
  border-radius: 0.35rem;
  width: fit-content;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  max-width: 88%;
}
@media (min-width: 1024px) {
  .message-r-c-a-m-s-s {
    max-width: 70%;
  }
}
.all-message-show-here-s {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
.message-text-a-m-s {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  max-width: 300px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
@media (min-width: 1024px) {
  .message-text-a-m-s {
    max-width: 614px;
  }
}
.moment-msg-t-a-m-s {
  margin-left: auto;
  width: fit-content;
  color: var(--color-primary);
  font-size: 0.8rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  gap: 4px;
}
.u-message-delete-icon {
  cursor: pointer;
  display: none;
}
.m-p-my-message-a-m-s-h:hover .moment-msg-t-a-m-s .u-message-delete-icon {
  display: flex;
}
.u-message-delete-icon svg {
  width: 0.85rem;
  height: 0.85rem;
  color: var(--color-gray);
}
.m-p-my-message-a-m-s-h {
  margin-left: auto;
}
.a-m-s-h-i-o-v-file-d {
  position: relative;
  width: 100%;
}
.a-m-s-h-img-s {
  object-fit: cover;
  height: 310px;
  border-radius: 11px;
  margin-top: 0.5rem;
  margin-bottom: 0.15rem;
  cursor: pointer;
  transition: all 0.45s;
}
.a-m-s-h-img-s:hover {
  transform: scale(0.97);
}
.a-m-s-h-vid-s {
  object-fit: cover;
  height: 310px;
  border-radius: 11px;
  margin-top: 0.5rem;
  margin-bottom: 0.15rem;
  cursor: pointer;
  width: 100%;
}
.u-w-f-p-r-s-s-a-m-m {
  width: 100%;
  position: relative;
}
.a-u-m-c-s-style {
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--color-white);
}
.user-f-a-l-n-c-s-a-u {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-overflow: ellipsis;
}
.a-u-chat-s-d-m {
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  gap: 0.5rem;
  align-items: center;
  border-radius: 10px;
  border-width: 1px;
  border-color: transparent;
  cursor: pointer;
}
.a-u-chat-s-d-m:hover {
  background: var(--color-light);
}
.a-u-chat-s-d-m:hover .a-u-m-c-s-style {
  border: 1px solid var(--color-primary);
}
.last-message-t-chat-sidebar-s {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 158px;
  font-size: 0.9rem;
}
@media (min-width: 1024px) {
  .last-message-t-chat-sidebar-s {
    max-width: 108px;
  }
}
.chat-sidebar-last-msg-uploaded-f-s {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
.chat-sidebar-last-msg-uploaded-f-s-svg-i {
  width: 1rem;
  height: 1rem;
}
.last-msg-a-c-s {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
.unseen-messages-number-chat-sidebar {
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  font-size: 0.8rem;
  padding: 0.8rem;
  background: var(--color-primary);
  color: white;
  font-weight: 500;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.error-m-p-u-f-p-c-s {
  max-width: 90%;
}
.error-m-p-u-f-p-c-s button {
  margin-top: 10px;
}
