.feedback-chat-popup {
  display: flex;
  position: fixed;
  bottom: 24px;
  right: 24px;
  height: 400px;
  width: 300px;
  background-color: var(--color-white);
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem;
  box-shadow: 0 0 1px var(--color-primary);
  border-radius: 10px;
  z-index: 11;
}
.feedback-chat-area {
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
}
.feedback-chat-badge {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
.feedback-chat-income-msg {
  display: flex;
  align-items: center;
}
.feedback-chat-msg {
  background-color: var(--color-light);
  color: var(--color-dark);
  padding: 0.5rem;
  border-radius: 25px;
  margin-left: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}
.feedback-chat-avatar {
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}
.feedback-chat-input-area {
  position: relative;
  display: flex;
  justify-content: center;
}
.feedback-chat-input-area input[type="text"] {
  width: 100%;
  border: 1px solid var(--color-dark);
  font-size: 1rem;
  border-radius: 5px;
  height: 2.2rem;
}
.feedback-chat-submit {
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
@media (max-width: 500px) {
  .feedback-chat-popup {
    width: 80vw;
  }
}
.close-f-chat-p-i-s {
  height: 1.2em;
  width: 1.2em;
}
.feedback-disappear-msg-container {
  margin-top: 16px;
}
.chat-m-page-c-c-s
  .messages-page-container-s
  .messages-page-header-s
  .feedback-chat-popup
  .feedback-chat-badge
  .close-f-chat-p-i-s
  svg {
  transform: translate(-2px, -2px);
}

.right .messages .feedback-chat-input-area .feedback-chat-submit {
  height: 2.5rem;
}
.feedback-chat-popup .feedback-chat-input-area input[type="text"] {
  color: var(--color-dark);
  background: var(--color-light);
}
.portfolio-body .contact__container .contact__form .feedback-chat-popup {
  bottom: 56px;
  right: 24px;
}
