.profile-cover-photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  height: 200px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
  background-color: var(--color-light);
  position: relative;
  cursor: pointer;
}
.profile-cover-photo-container img {
  width: 100%;
  height: 100%;
  object-position: center;
  border-radius: var(--card-border-radius);
  object-fit: cover;
}
.update-cover-wrapper {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.open-cover-update {
  background: var(--color-white);
  padding: 7px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  border-radius: var(--card-border-radius);
  cursor: pointer;
}
.open-cover-update i {
  transform: scale(0.9);
}
.open-cover-menu {
  background: var(--color-white);
  position: absolute;
  right: 0;
  padding: 10px;
  width: 200px;
  border-radius: var(--card-border-radius);
  box-shadow: 0 0 1rem var(--color-primary);
  z-index: 2;
  margin-top: 4px;
}
.open-cover-menu-item {
  display: flex;
  gap: 10px;
  padding: 8px;
  cursor: pointer;
  font-weight: 600;
  border-radius: var(--card-border-radius);
}
.profile-image-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
.profile-w-left {
  display: flex;
  gap: 1rem;
  padding: 1rem 13px 0 14px;
}
.profile-w-img {
  position: relative;
}
.profile-w-bg {
  width: 140px;
  height: 140px;
  transform: translateY(-3.8rem);
  border-radius: 50%;
  background-color: var(--color-light);
  background-repeat: no-repeat;
  border: 2px solid var(--color-primary);
  cursor: pointer;
}
.profile-w-bg:hover {
  filter: bightness(95%);
  border: 2px solid var(--color-dark);
}
.profile-circle {
  position: absolute;
  bottom: 0;
  right: 0.2rem;
  transform: translateY(-8px);
  background: var(--color-light);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.profile-name {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
}
.othername-a-s {
  font-size: 0.8rem !important;
  color: var(--color-gray);
}
.profile-c-buttons {
  gap: 10px;
}
.profile-w-right {
  display: flex;
  gap: 10px;
}
.profile-c-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-name {
  padding-bottom: 6px;
}
.padding-b-p-p-s {
  padding-bottom: 6px;
}
@media (max-width: 900px) {
  .profile-w-left {
    flex-direction: column;
    align-items: center;
  }
  .profile-w-col {
    transform: translateY(-3.6rem);
  }
  .profile-image-wrap {
    flex-direction: column;
    justify-content: center;
  }
  .profile-circle {
    right: 0;
  }
  .profile-w-right {
    place-items: center;
  }
  .profile-c-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.p-y-m-k-h {
  color: var(--color-gray);
  margin: 1rem 0;
}

.p-y-m-k-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
}

.p-y-m-k-box {
  flex: 1 1 calc(50% - 10px);
  box-sizing: border-box;
  padding: var(--card-padding);
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  overflow: hidden;
  max-width: calc(50% - 10px);
}

.info-p-y-m-k {
  display: flex;
  align-items: start;
  gap: 0.8rem;
  margin-bottom: 1rem;
}

.action-p-y-m-k {
  display: flex;
  gap: 10px;
}

.btn-small {
  padding: 0.4rem 1.2rem;
  font-size: 0.875rem;
}

/* Media Queries */
@media (max-width: 600px) {
  .p-y-m-k-box {
    flex: 1 1 calc(50% - 5px);
    max-width: calc(50% - 5px);
  }
}

@media (max-width: 400px) {
  .p-y-m-k-box {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
.no-posts {
  margin-top: 16px;
  margin-bottom: 16px;
  color: var(--color-gray);
  font-weight: 500;
}
.outer-f-t-s {
  color: var(--color-gray);
  margin: 1rem 0;
}
.see-a-photos {
  cursor: pointer;
  color: var(--color-gray);
}
.see-a-photos:hover {
  color: var(--color-primary);
}
.profile-card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.profile-photo-card {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.profile-photo-card img {
  width: 100%;
  height: 124px;
  object-fit: cover;
  border-radius: var(--card-border-radius);
  transition: all 0.6s;
}
.profile-photo-card:hover img {
  transform: scale(1.1);
}
.friends-n-profile-p {
  font-weight: 600;
  display: flex;
  place-items: center;
  justify-content: center;
  text-align: center;
}
.profile_photo_card {
  cursor: pointer;
}
.friends-p-pic-b-s {
  margin-bottom: 4px;
}
.profile_photo_card img {
  border-radius: 50% !important;
}
.profile_photo_card img:hover {
  border: 2px solid var(--color-primary);
}
.profile-cover-photo-container img {
  overflow: visible !important;
  object-fit: cover;
}
.cover-crooper .reactEasyCrop_CropArea {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}
.p-p-blue_btn-cancel {
  border: none;
  outline: none;
  background: white;
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}
.save-changes-cover {
  z-index: 999999999;
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}
.save-changes-left {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-white);
  margin-bottom: 8px;
}
.save-changes-right {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: right;
}
.cover-img-c-s {
  overflow: hidden;
  border-radius: var(--card-border-radius);
}
.cover-img-c-s:hover {
  box-shadow: 0 0 0.5rem var(--color-primary);
}
.cover-img-c-s:hover img {
  transition: all 0.2s;
  transform: scale(1.1);
}
.cover-img-c-s img {
  object-fit: cover;
  border-radius: var(--card-border-radius);
}
.camera-filled-icon svg {
  width: 20px;
  height: 20px;
  display: flex;
  text-align: center;
}
.profile-c-buttons-o-v {
  display: flex;
  max-width: 100%;
  gap: 10px;
}
.profile-c-buttons-o-v button {
  padding: 10px 20px;
  max-width: 50%;
  text-align: center;
}
.profile-c-buttons-o-v a {
  padding: 10px 20px;
  max-width: 50%;
  text-align: center;
  text-decoration: none;
  background-color: rgb(240, 240, 240);
  color: black;
}
/* ------------------------------------ */
/* ------------------------------------ */
/* ------------------------------------ */
.mes-btn-c-p {
  text-align: center;
  margin-top: 10px;
}
.mes-btn-p {
  background-color: var(--color-light);
}
.friends_menu_wrap {
  position: relative;
}
.open_cover_menu {
  background: var(--color-white);
  position: absolute;
  bottom: 46px;
  padding: 10px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 0 0.85rem var(--color-primary);
  z-index: 1;
}
.open_cover_menu_item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 10px;
}
.not-friends-message-btn-p-p:hover {
  border: 1px solid var(--color-dark);
}
.friends-message-btn-p-p:hover {
  border: 1px solid var(--color-primary);
}
.profile-page-count-p-s {
  color: var(--color-gray);
  transform: translateY(-10px);
  font-weight: 600;
  display: flex;
  justify-content: right;
  margin-top: 4px;
}
.profile-page-count-p-s {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  line-height: 12px;
}
.profile_friend_count:nth-child(1),
.profile_friend_count:nth-child(2) {
  order: 1;
}
.profile_friend_count:nth-child(3) {
  order: 2;
  width: 100%; /* Makes the third element take the full width */
  text-align: right;
}
.profile_card_f_count {
  margin-bottom: 6px;
}

.profile_friend_imgs {
  display: flex;
  transform: translateY(-10px);
}
.profile_friend_imgs a {
  width: 2.3rem;
  height: 2.3rem;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--color-white);
  margin-left: -0.6rem;
}
.profile_friend_imgs a img {
  width: 2.3rem;
  height: 2.3rem;
  object-fit: cover;
}
.profile_friend_imgs a:first-child {
  margin: 0;
}
.profile_friend_imgs a:hover {
  box-shadow: 0 0 0.3rem var(--color-primary);
  img {
    transform: scale(1.1);
  }
}
.profile-a-c-m-p-i-s svg {
  height: 1.3rem;
  width: 1.3rem;
}

.react-loading-skeleton {
  --base-color: var(--color-light) !important;
  --highlight-color: var(--color-secondary) !important;
}
.profile-cover-loading-s-s {
  width: 100%;
  min-height: 100px;
  height: 200px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
  position: relative;
}
.profile-img-loading-s-s {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.p-f-i-loading-s-s {
  margin-top: 2px;
}
.p-p-a-btns-loading-s-s {
  width: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
  height: 38px;
  display: flex;
  align-items: center;
}
.profile-d-loader-c-s {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-posts-loader-c {
  display: grid;
  place-items: center;
  padding: 10px 0;
  margin-bottom: 16px;
}
.d-n-a-o-s-p {
  display: none;
}
.profile-t-f-m-s {
  margin-top: 0 !important;
}
.friends-p-b-t-s {
  margin-bottom: 1.4rem;
}
.u-l-n-p-p-s-i svg {
  height: 1.2rem;
  width: 1.2rem;
  transform: translateY(2px);
}
