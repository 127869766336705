.user-stories {
  background: var(--color-white);
  border-radius: 20px;
  padding: 8px 14px;
  margin-bottom: 15px;
}

.stories-wrapper {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  gap: 10px;
  height: 170px;
  margin-bottom: 12px;
}

.mini-headign {
  color: var(--color-dark);
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
}
.single-stories {
  width: 100%;
  position: relative;
  padding-top: 25px;
}
.single-stories label {
  width: 45px;
  height: 45px;
  background: var(--color-light);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--color-primary);
}
.single-stories label img {
  width: 100%;
}
.single-stories > div {
  width: 100%;
  overflow: hidden;
  height: 100%;
  border-radius: 14px;
  text-align: center;
  box-shadow: 1px 6px 6px 0 var(--shadow);
}
.single-stories > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single-stories > div b {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: white;
  font-weight: 400;
  text-shadow: 0 1px 8px black;
}
.single-stories > div i {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 25px;
  color: white;
}
.story-img-fit img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
.story-text-control {
  overflow: hidden;
  cursor: pointer;
}

.spin {
  color: var(--color-dark);
}
.spin:after {
  content: "";
  animation: spin 200s linear infinite;
}
@keyframes spin {
  0% {
    content: "Welcome to Namrolink!";
  }
  10% {
    content: "Welcome to Namrolink!";
  }
  20% {
    content: "Namrolink greets you warmly.";
  }
  30% {
    content: "Namrolink greets you warmly.";
  }
  40% {
    content: "Namrolink awaits you.";
  }
  50% {
    content: "Namrolink awaits you.";
  }
  60% {
    content: "Enter Namrolink's world.";
  }
  70% {
    content: "Enter Namrolink's world.";
  }
  80% {
    content: "Explore Namrolink's wonders.";
  }
  90% {
    content: "Explore Namrolink's wonders.";
  }
}
.invisible-span {
  visibility: hidden;
}

.single-stories:hover .home-story-background {
  transform: scale(1.1);
}

.home-story-background {
  transition: all 0.45s;
}

.single-stories {
  overflow: hidden;
}

.story-back-container-home {
  overflow: hidden;
  background-color: var(--color-light);
}
.story-img-fit {
  z-index: 1;
}
.user-stories .mini-headign .w-m-h-m-s-s {
  text-align: center;
}
