.posts {
  margin-top: 1rem;
  width: 100%;
}
.post {
  position: relative;
  width: 685px;
  background: var(--bg-primary);
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  user-select: none;
  color: var(--color-primary);
  margin-bottom: 10px;
}
.post_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.post_header_left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.post_header_left img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.header_col {
  display: flex;
  flex-direction: column;
}
.post_profile_name {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: var(--color-primary);
}
.updated_p {
  color: var(--color-secondary);
  font-weight: normal;
}
.post_profile_privacy_date {
  margin-top: 1px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--color-secondary);
}
.post_bg {
  height: 500px;
  padding: 15px;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 22px;
  background-repeat: no-repeat;
  background-size: cover;
}
.post_text {
  padding: 0 15px 10px 15px;
}
.post_bg_text {
  color: #000;
}

/*-----------------------------GRID IMAGES----------------*/
.grid_1 img {
  width: 100%;
  object-fit: cover;
  border-radius: var(--card-border-radius);
  object-fit: cover;
  max-height: 490px;
}
.grid_1 {
  display: flex;
  justify-content: center;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.grid_2 {
  height: 100%;
  display: flex;
  gap: 8px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.grid_2 img {
  width: 50%;
  border-radius: var(--card-border-radius);
  object-fit: cover;
  max-height: 300px;
}
.grid_3 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
}
.grid_3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid_3 .img-0 {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  object-fit: cover;
}
.grid_3 .img-1 {
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  object-fit: cover;
  max-height: 210px;
}
.grid_3 .img-2 {
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  object-fit: cover;
  max-height: 210px;
}
.grid_4 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
}

.grid_4 img {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1; /* Ensures equal width and height */
  max-height: 200px;
}

.grid_4 .img-0,
.grid_4 .img-1,
.grid_4 .img-2,
.grid_4 .img-3 {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.grid_5 {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.grid_5 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  grid-column: auto / span 2;
  border-radius: var(--card-border-radius);
  max-height: 200px;
}
.grid_5 img:nth-child(1),
.grid_5 img:nth-child(2) {
  grid-column: auto / span 3;
}

.more-pics-shadow {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 49%;
  width: 32%;
  background: #94949454;
  font-size: 32px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.share-icon-f-shrink svg {
  height: 1.2rem;
  width: 1.2rem;
}
.share-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.share-icons-container .share-icon svg {
  max-height: 1.6rem;
  max-width: 1.6rem;
}

.share-icon {
  cursor: pointer;
  transition: transform 0.2s;
}

.share-icon:hover {
  transform: scale(1.1);
}
.share-icon-wrapper {
  position: relative;
}

.share-popup {
  position: absolute;
  top: -56px;
  left: -10px;
  background: var(--color-white);
  box-shadow: 0 0 1rem var(--color-primary);
  border-radius: 12px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 1;
}
.share-popup:hover {
  color: var(--color-dark);
}
.share-icon:hover {
  color: var(--color-primary);
}
/*------Post menu------*/
.post-middle-p-r {
  position: relative;
}
.hover1-m-p:hover {
  background: var(--color-light);
}
.post_menu {
  background: var(--color-white);
  width: 290px;
  box-shadow: 0 0 1rem var(--color-primary);
  border-radius: 14px;
  position: absolute;
  top: 3.1rem;
  right: 10px;
  z-index: 4;
  font-family: inherit;
  line-height: 19px;
  padding: 10px;
}
.post_menu li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.post_menu_text {
  display: flex;
  flex-direction: column;
}
.menu_post_col {
  font-size: 12px;
  color: #65676b;
}
.line {
  width: 100%;
  height: 1px;
  background: #ced0d4;
  margin: 5px 0;
}
.post-profile-wrap {
  position: relative;
  display: grid;
  place-items: center;
}
.post-updated-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--color-light);
}
.post-updated-picture {
  z-index: 1;
  border-radius: 50% !important;
  transform: scale(0.9);
  object-fit: cover;
  border: 2px solid var(--color-primary);
}
.post-cover-wrap img {
  width: 100%;
  object-fit: cover;
  border-radius: var(--card-border-radius);
  object-fit: cover;
  max-height: 510px;
}
.cover-post-p-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-profile-wrap .post-updated-bg .cover-post-p-bg {
  transition: filter 0.2s ease;
}

.post-profile-wrap:hover .post-updated-bg .cover-post-p-bg {
  filter: grayscale(40%);
}
.post-cover-wrap img:hover {
  transition: all 0.6s;
  transform: scale(1.02);
}
/* ---------------------------------- */
/* ---------------------------------- */
/* ---------------------------------- */
.profile-photo-c-comment-s img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}
.profile-photo-c-comment-s img:hover {
  box-shadow: 0 0 0.2rem var(--color-primary);
}
.create-comment-wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 0.5rem;
}
.create-comment {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-light);
  border-radius: var(--border-radius);
}
.comment-input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem var(--card-padding);
  background-color: var(--color-light);
  border-radius: var(--border-radius);
  position: relative;
}
.comment-input-container input[type="text"] {
  justify-self: start;
  width: 100%;
  height: 2rem;
  padding-left: 0.2rem;
  margin-right: 0.2rem;
  background: transparent;
  color: var(--color-dark);
}
label.comment-add-s {
  margin-right: 0.5rem;
  font-size: 1.4rem;
  cursor: pointer;
}
label.comment-add-s-l-s {
  position: absolute;
  top: 13px;
  right: 8.5rem;
}
label.comment-add-s:hover {
  color: var(--color-primary);
}
.imported_icon-c-s svg {
  display: flex;
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
}
.comment-s-btn-s {
  padding: 0.3rem 1.2rem;
  border-radius: 15px;
  display: flex;
  justify-content: center;
}
.comment-s-btn-s svg {
  height: 1.25rem;
  width: 1.25rem;
}
.comment-emoji-picker-p .emoji-picker-react {
  position: absolute;
  right: 1px;
  bottom: 3.4rem;
  z-index: 1;
  height: 250px;
}
.comment_img_preview {
  position: relative;
  margin-top: 5px;
  margin-left: 40px;
  width: 120px;
  max-height: 140px;
  border-radius: 10px;
  overflow: hidden;
}
.comment_img_preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.comment_img_preview .small_white_circle {
  top: -2px;
  right: -2px;
  transform: scale(0.8);
  z-index: 1;
}
.u-comment {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}
.comment_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.comment_image {
  margin-top: 5px;
  height: 150px;
  max-width: 250px;
  border-radius: 12px;
  object-fit: cover;
}
.comment_wrap {
  background-color: var(--color-light);
  padding: 5px 15px;
  border-radius: 20px;
}
.comment_actions {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  color: var(--color-primary);
  margin-left: 10px;
}
.view-more-comments-s:hover {
  text-decoration: underline;
}
.comment_name {
  font-weight: 510;
}
.comment_text {
  font-weight: 400;
  line-height: 1.5;
}
.u-f-comment_error {
  padding: 0 !important;
  opacity: 1 !important;
  box-shadow: 0 0 1rem var(--color-primary);
  z-index: 1 !important;
}
.share-icon-wrapper:hover .share-popup {
  color: var(--color-dark);
}
.delete-comment-s {
  font-weight: 500;
  margin-left: 6px;
  margin-right: 10px;
  cursor: pointer;
}
.link-container-s {
  position: relative;
}
.link-c-m-s {
  position: absolute;
  bottom: 44px;
  font-size: 16px;
  font-weight: 400;
  background: var(--color-primary);
  color: white;
  border-radius: 8px;
  padding: 4px 10px;
}
.copied-o-s {
  z-index: 1;
}
.menu-item-i-s svg {
  width: 1.2rem;
  height: 1.2rem;
}
.post-middle-p-r .photo {
  cursor: pointer;
}
.close-icon-s-comment-img-s svg {
  width: 1.2rem;
  height: 1.2rem;
  transform: translateY(3px);
}

.posted-video-container {
  display: flex;
  justify-content: center;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.posted-video-container video {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: var(--card-border-radius);
  object-fit: cover;
  max-height: 400px;
}
