.ad-p-i-c-s {
  border-radius: 50%;
  background-color: var(--color-light);
  display: flex;
  justify-content: center;
}
.ad-profile-a-img-s {
  object-fit: cover;
}
.as-u-t-f-l-n-s {
  display: flex;
  align-items: center;
  gap: 6px;
}
.as-u-t-f-l-n-s svg {
  height: 1.2rem;
  width: 1.2rem;
}
.advertisement-preview3 {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.advertisement-preview3 img {
  width: 100%;
  height: 180px;
  border-radius: var(--card-border-radius);
  object-fit: cover;
  cursor: pointer;
}
.advertisement-preview3 div {
  width: 100%;
  height: 180px;
  cursor: pointer;
  object-fit: cover;
  border-radius: var(--card-border-radius);
  background-color: var(--color-light);
  min-height: 170px;
}
.advertisement-preview3 div:nth-child(1) {
  grid-column: 1/3;
}
.advertisement-i-slider {
  position: relative;
}
.advertisement-i-slider .list .useritem {
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
  opacity: 0;
}
.advertisement-i-slider .list .useritem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.advertisement-i-slider .list .useritem.useractivei {
  opacity: 1;
  z-index: 10;
}
.advertisement-i-slider svg {
  height: 1.1rem;
  width: 1.1rem;
}
.prev-ad-m-u-a {
  position: absolute;
  top: 5.5%;
  right: 10%;
  z-index: 100;
}
.next-ad-m-u-a {
  position: absolute;
  top: 5.5%;
  right: 4%;
  z-index: 100;
}
.advertisement-m-userthumbnail {
  position: absolute;
  bottom: -140px;
  z-index: 11;
  display: flex;
  gap: 10px;
  width: 100%;
  height: 40px;
  padding: 0 50px;
  box-sizing: border-box;
  overflow: auto;
  justify-content: center;
  background: none !important;
}
.advertisement-m-userthumbnail::-webkit-scrollbar {
  width: 0;
}
.advertisement-m-userthumbnail .useritem {
  width: 30px !important;
  height: 30px !important;
  filter: brightness(0.5);
  transition: 0.5s;
  overflow: hidden;
  background: none !important;
  cursor: pointer;
}
.advertisement-m-userthumbnail .useritem img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 12px;
}
.advertisement-m-userthumbnail .useritem.useractivei {
  filter: brightness(1.5);
}

/* --------------------------------- */
.advertisement-m-userthumbnail::-webkit-scrollbar {
  display: none !important;
}
.advertisement-m-userthumbnail {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
.ad-img-p-container-m {
  z-index: 1 !important;
}
/* ------------ */
.popup-m-img-p-o-a-s {
  animation: zoom-in-zoom-out 8s linear infinite;
}
.p-i-z-o-m-s {
  overflow: hidden;
  z-index: 1;
}
@keyframes zoom-in-zoom-out {
  0%,
  100% {
    transform: scale(1); /* Original size */
  }
  25% {
    transform: scale(1.05); /* Slight zoom in */
  }
  50% {
    transform: scale(1.1); /* More zoom in */
  }
  75% {
    transform: scale(1.05); /* Slight zoom back out */
  }
}
.changing-ad-img-d-s {
  z-index: 1;
}
