/* ---------------------------------THEME CUSTOMIZATION----------------------------- */
.customize-theme {
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  display: grid;
  place-items: center;
  font-size: 0.9rem;
  display: none;
}

.customize-theme .card-container {
  position: relative;
  background: var(--color-white);
  padding: 3rem;
  border-radius: var(--card-border-radius);
  width: 50%;
  box-shadow: 0 0 1rem var(--color-primary);
}

.customize-theme .card-container .close-theme {
  position: absolute;
  top: 1rem;
  right: 1.2rem;
  cursor: pointer;
}

/* ------------FONT SIZE------------ */
.customize-theme .font-size {
  margin-top: 1.5rem;
}

.customize-theme .font-size > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-light);
  padding: var(--search-padding);
  border-radius: var(--card-border-radius);
}

.customize-theme .font-size .choose-size {
  background: var(--color-secondary);
  height: 0.3rem;
  width: 100%;
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customize-theme .font-size .choose-size span {
  width: 1rem;
  height: 1rem;
  background: var(--color-secondary);
  border-radius: 50%;
  cursor: pointer;
}

.customize-theme .font-size .choose-size span.activeitem {
  background: var(--color-primary);
}

/* ------------COLOR------------ */
.customize-theme .color {
  margin-top: 1.5rem;
}

.customize-theme .color .choose-color {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-light);
  padding: var(--search-padding);
  border-radius: var(--card-border-radius);
}

.customize-theme .color .choose-color span {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  cursor: pointer;
}

.customize-theme .color .choose-color span.activeitem {
  border: 5px solid white;
}

.customize-theme .color .choose-color span:nth-child(1) {
  background: hsl(252, 75%, 60%);
}
.customize-theme .color .choose-color span:nth-child(2) {
  background: hsl(52, 75%, 60%);
}
.customize-theme .color .choose-color span:nth-child(3) {
  background: hsl(352, 75%, 60%);
}
.customize-theme .color .choose-color span:nth-child(4) {
  background: hsl(152, 75%, 60%);
}
.customize-theme .color .choose-color span:nth-child(5) {
  background: hsl(202, 75%, 60%);
}

/* ------------BACKGROUND------------ */
.customize-theme .background {
  margin-top: 1.5rem;
}

.customize-theme .background .choose-bg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
}

.customize-theme .background .choose-bg > div {
  width: 100%;
  padding: var(--card-padding);
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.4rem;
  cursor: pointer;
}

.customize-theme .background .choose-bg > div.activeitem {
  border: 4px solid var(--color-primary);
}

.customize-theme .background .choose-bg .bg-1,
.customize-theme .background .choose-bg .bg-1 h5 {
  background: white;
  color: black;
}
.customize-theme .background .choose-bg .bg-2,
.customize-theme .background .choose-bg .bg-2 h5 {
  background: hsl(252, 30%, 17%);
  color: white;
}
.customize-theme .background .choose-bg .bg-3,
.customize-theme .background .choose-bg .bg-3 h5 {
  background: hsl(252, 30%, 10%);
  color: white;
}

.customize-theme .background .choose-bg > div span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid var(--color-gray);
  margin-right: 1rem;
}
.close-theme-window {
  display: flex;
  height: 1rem;
  width: 1rem;
}
