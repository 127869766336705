/*-------User menu----*/

.mmenu {
  padding: 0 0.3rem;
  position: absolute;
  top: 100%;
  right: 8px;
  width: 360px;
  border-radius: 10px;
  background-color: var(--color-white);
  box-shadow: 0 0 1rem var(--color-primary);
  user-select: none;
  padding: 10px;
}
.mmenu_header {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  gap: 10px;
}
.mmenu_header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.mmenu_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mmenu_col span:first-of-type {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 15px;
}
.mmenu_col span:last-of-type {
  font-size: 14px;
}
.mmenu_main {
  padding: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.mmenu_splitter {
  width: 100%;
  height: 1px;
  background: var(--bg-third);
  margin-top: 5px;
}
.mmenu_span1 {
  font-size: 14px !important;
}
.mmenu_span2 {
  font-size: 12px !important;
  color: var(--color-gray);
}
.mmenu_item {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 7px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}
.rArrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.absolute_wrap {
  padding: 0 0.3rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: fit-content;
  border-radius: 10px;
  background: var(--color-white);
  box-shadow: 0 0 1rem var(--color-primary);
  user-select: none;
  padding: 10px;
}
.absolute_wrap .mmenu_item {
  margin-top: 0;
}
.absolute_wrap_header {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 24px;
}

.absolute_wrap label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.absolute_wrap label input {
  width: 20px;
  height: 20px;
}
.search_user_item i {
  position: absolute;
  right: 10px;
  transform: scale(0.6);
}

/*-------User menu----*/
/* ------------------ */
/* ------------------ */
.u-m-search-s {
  position: relative;
}
.u-search-menu {
  position: absolute;
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  box-shadow: 0 0 0.8rem var(--color-primary);
  min-height: 300px;
  max-height: 300px;
  overflow: hidden;
  width: 100%;
  top: 52px;
  padding: 1.3rem;
}
.u-search-menu-c-c {
  overflow: scroll;
  max-height: 300px;
}
.u-search-menu-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.u-search-menu-title {
  font-weight: 600;
}
.u-search-menu-close {
  color: var(--color-primary);
  cursor: pointer;
  margin-right: 8px;
}
.search-m-exit-i-s svg {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}
/* ------------------ */
/* ------------------ */
.recent-u-search-h-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.2rem 0;
  border-radius: 14px;
}
.recent-u-search-h-item .recent-u-search-h-l img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border: 0.3px solid var(--color-primary);
  border-radius: 50%;
  margin-left: 8px;
}
.recent-u-search-h-item i {
  margin-right: 8px;
}
.recent-u-search-h-l {
  display: flex;
  align-items: start;
  gap: 1rem;
  margin: 0.8rem 0;
}
/* ------------------ */
/* ------------------ */
/* ------------------ */
.u-search-r-h-item-c-s {
  padding: 0.6rem 0;
  border-radius: 14px;
}
.u-search-r-h-item {
  display: flex;
  align-items: start;
  gap: 1rem;
  margin: 0.2rem 0;
}
.u-search-r-h-item img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border: 0.3px solid var(--color-primary);
  border-radius: 50%;
  margin-left: 8px;
}
.search-menu-l-s-s {
  margin-bottom: 16px;
}
/* ------------------ */
.seach-menu-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}
/* ------------------ */
/* ------------------ */
/* ------------------ */
.search_results {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
}
.search_history {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
}
.search_user_item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.search_user_item img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.search_user_item span {
  font-weight: 600;
  font-size: 14px;
}

/*---responsive----------*/
@media (max-width: 1295px) {
  .middle_icon {
    width: 90px;
  }
}
@media (max-width: 1130px) {
  .middle_icon {
    width: 70px;
  }
}
@media (max-width: 1040px) {
  .search1 {
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
  }
  .search1 input {
    display: none;
  }
  .header_middle {
    transform: translateX(-15%);
  }
}
@media (max-width: 945px) {
  .header_middle {
    transform: translateX(-22%);
  }
}
@media (max-width: 850px) {
  .header_middle {
    transform: translateX(-30%);
  }
  .middle_icon:nth-child(5) {
    display: none;
  }
}
@media (max-width: 770px) {
  .header_middle {
    transform: translateX(-37%);
  }
  .middle_icon:nth-child(4) {
    display: none;
  }
}
@media (max-width: 720px) {
  .middle_icon:nth-child(3) {
    display: none;
  }
}
/*---responsive----------*/
@media (max-width: 620px) {
  .profile_link {
    display: none;
  }
  .all_menu {
    width: 485px;
  }
  .all_left {
    width: 250px;
  }
}
@media (max-width: 550px) {
  .middle_icon:nth-child(1) {
    display: none;
  }
  .header_middle {
    transform: translateX(-20%);
  }
}
@media (max-width: 505px) {
  .all_menu {
    width: 340px;
    right: -9rem;
  }
  .all_menu_wrap {
    grid-template-columns: 1fr;
  }
  .all_left {
    width: 100%;
  }
  .all_right {
    position: relative;
    width: 100%;
    padding-left: 3rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 400px) {
  .header_right .circle_icon:nth-child(3) {
    display: none;
  }
  .mmenu {
    width: 290px;
  }
  .all_menu {
    width: 310px;
    left: -10rem;
  }
  .search_area {
    width: 310px;
  }
}
/*---responsive----------*/

/*----Active header----*/
.active_header {
  background: var(--light-blue-color);
}
.active_header svg {
  fill: var(--blue-color);
}
.active_link {
  background: var(--light-blue-color);
  color: var(--blue-color);
}
/*----Active header----*/

.font-size-header-m > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-light);
  padding: var(--search-padding);
  border-radius: var(--card-border-radius);
}
.choose-size-header-m {
  background: var(--color-secondary);
  height: 0.3rem;
  width: 95%;
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.choose-size-header-m span {
  width: 1rem;
  height: 1rem;
  background: var(--color-secondary);
  border-radius: 50%;
  cursor: pointer;
}

.choose-color-header-m {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-light);
  padding: var(--search-padding);
  border-radius: var(--card-border-radius);
}
.choose-color-header-m span {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  cursor: pointer;
}

.choose-color-header-m span:nth-child(1) {
  background: hsl(252, 75%, 60%);
}
.choose-color-header-m span:nth-child(2) {
  background: hsl(52, 75%, 60%);
}
.choose-color-header-m span:nth-child(3) {
  background: hsl(352, 75%, 60%);
}
.choose-color-header-m span:nth-child(4) {
  background: hsl(152, 75%, 60%);
}
.choose-color-header-m span:nth-child(5) {
  background: hsl(202, 75%, 60%);
}
.choose-bg-header-m {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
}
.choose-bg-header-m > div {
  width: 100%;
  padding: var(--card-padding);
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.4rem;
  cursor: pointer;
}

.choose-bg-header-m .bg-1-h-m,
.choose-bg-header-m .bg-1-h-m h5 {
  background: white;
  color: black;
}
.choose-bg-header-m .bg-2-h-m,
.choose-bg-header-m .bg-2-h-m h5 {
  background: hsl(252, 30%, 17%);
  color: white;
}
.choose-bg-header-m .bg-3-h-m,
.choose-bg-header-m .bg-3-h-m h5 {
  background: hsl(252, 30%, 10%);
  color: white;
}
.choose-bg-header-m > div span {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: 2px solid var(--color-gray);
  margin-right: 0.8rem;
}
.header-menu-spacing-element-s {
  margin-top: 6px;
}
.card-container-header-m {
  margin-top: 3px;
  margin-bottom: 3px;
}
.choose-size-header-m span.activeitem {
  background: var(--color-primary);
}
.choose-color-header-m span.activeitem {
  border: 5px solid white;
}
.choose-bg-header-m > div.activeitem {
  border: 4px solid var(--color-primary);
}
.user-menu-icon-s svg {
  height: 1.3rem;
  width: 1.3rem;
  transform: translate(1.4px, 3px);
}
.c-p-o-user-m-s {
  border-radius: 10px;
}
.right-arrow-icon-u-m svg {
  height: 1.3rem;
  width: 1.3rem;
}
.arrow-back-icon-u-m-s svg {
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
}
.d-a-a-a-i-s {
  border-radius: 8px;
}
@media screen and (max-width: 430px) {
  .choose-bg-header-m {
    gap: 0.75rem;
  }
  .choose-bg-header-m > div span {
    margin: 0 0.4rem 0 0.35rem;
  }
  .choose-bg-header-m > div {
    padding: 0.85rem 0.3rem;
  }
}
