.postBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-white);
  box-shadow: 0 0 1rem var(--color-primary);
  min-height: 228px;
  width: 500px;
  border-radius: 10px;
}
.box_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  padding: 14px 15px 17px 15px;
  border-bottom: 1px solid var(--color-gray);
}
.box_header span {
  color: var(--color-dark);
}
.postBox .small_circle {
  position: absolute;
  right: 16px;
  top: 12px;
}
.box_profile {
  display: flex;
  gap: 12px;
  padding: 17px 17px 5px 17px;
}
.box_profile .box_profile_img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
}
.box_col {
  transform: translateY(-7px);
  color: var(--color-dark);
}
.box_profile_name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}
.box_privacy {
  display: flex;
  align-items: center;
  width: 86px;
  gap: 4px;
  font-size: 12px;
  padding: 3px 8px 4px 8px;
  border-radius: 7px;
  background: var(--color-light);
  cursor: pointer;
}
.box_privacy svg {
  height: 1.1rem;
  width: 1.1rem;
}
.box_privacy span {
  margin-bottom: 1px;
}
.flex_center {
  min-height: 90px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.post_input {
  position: relative;
  outline: none;
  border: none;
  resize: none;
  padding: 5px 15px;
  margin-bottom: 10px;
  width: 94%;
  margin-left: 1px;
  font-family: inherit;
  font-size: 24px;
  background: var(--bg-primary);
  color: #000;
}
.flex_center textarea {
  min-height: 90px;
  background: transparent;
  overflow-y: visible;
  position: absolute;
}
.post_emojis_wrap {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 15px;
}
.post_emojis_wrap img {
  width: 39px;
  cursor: pointer;
}
.post_emojis_wrap i:hover {
  color: var(--color-primary);
}
.comment_emoji_picker {
  position: absolute;
  right: 0;
  bottom: 3rem;
}
.rlmove {
  right: -7rem;
}
.addtoyourpost {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 10px;
  padding: 10px;
  border-radius: 10px;
  border: 3px solid var(--bg-forth);
}
.addto_text {
  width: 50%;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-primary);
}
.post_header_right {
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  cursor: pointer;
  border-radius: 50%;
}
.post_submit {
  width: calc(100% - 18px);
  margin: 5px 10px;
  padding: 10px;
  outline: none;
  border: none;
  background: var(--color-primary);
  color: #fff;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
}
.overflow_a {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.input2 {
  position: relative;
  display: block;
  font-size: 15px;
  min-height: 15px !important;
}
.moveleft {
  transform: translateX(-10px);
}
.movepicker2 {
  right: 1rem;
  top: 9.7rem;
  z-index: 9999;
}
.images_input {
  display: flex;
}
.add_pics_wrap {
  margin: 10px 15px;
  padding: 10px;
  border: 1px solid var(--bg-third);
  border-radius: 10px;
  color: var(--color-primary);
  min-height: 100px;
}
.add_pics_inside1 {
  position: relative;
  background: var(--color-light);
  border-radius: 10px;
  padding: 10px;
  height: 250px;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.add_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}
.small_white_circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--color-dark);
  background: var(--color-light);
  display: grid;
  place-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 99;
}
.add_circle {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid var(--bg-third);
  background: var(--bg-third);
  display: grid;
  place-items: center;
}
.add_col span:first-of-type {
  font-size: 15px;
  font-weight: 600;
}
.add_col span:last-of-type {
  font-size: 12px;
  color: var(--color-secondary);
}
.add_pics_inside2 {
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--bg-secondary);
  padding: 10px;
  border-radius: 10px;
}
.mobile_text {
  font-size: 12px;
  font-weight: 600;
}
.addphone_btn {
  background: var(--bg-third);
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.p0 {
  padding: 0;
  border-radius: 10px;
  height: 100%;
  cursor: default;
  overflow: hidden;
}
.preview_actions {
  position: absolute;
  left: 1rem;
  top: 1rem;
  display: flex;
  gap: 1rem;
}
.preview_actions button {
  outline: none;
  border: none;
  padding: 8px 10px;
  border-radius: 7px;
  background: var(--color-white);
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  font-family: inherit;
  color: var(--color-dark);
}
.p0:hover .preview_actions {
  display: flex;
}
.preview1 {
  width: 100%;
  height: 100%;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.preview1 img {
  width: 100%;
  height: 100%;
  border-radius: var(--card-border-radius);
  object-fit: cover;
}
.preview1 video {
  width: 100%;
  height: 100%;
  border-radius: var(--card-border-radius);
  object-fit: cover;
}
.preview2 {
  height: 100%;
  display: flex;
  gap: 8px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.preview2 img {
  width: 50%;
  height: 230px;
  border-radius: var(--card-border-radius);
  object-fit: cover;
}
.preview3 {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.preview3 img {
  width: 100%;
  height: 200px;
  border-radius: var(--card-border-radius);
  object-fit: cover;
}
.preview3 img:nth-child(1) {
  grid-column: 1/3;
}
.preview4 {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.preview4 img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: var(--card-border-radius);
}
.preview4 img:nth-child(1) {
  grid-column: 1/4;
}
.preview5 {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.preview5 img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  grid-column: auto / span 2;
  border-radius: var(--card-border-radius);
}
.preview5 img:nth-child(1),
.preview5 img:nth-child(2) {
  grid-column: auto / span 3;
}
.preview6 {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  border-radius: var(--card-border-radius);
  overflow: hidden;
}
.preview6 img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: var(--card-border-radius);
  object-fit: cover;
}
.singular_grid img:nth-child(1) {
  grid-column: 1/3;
}
.post_backgrounds {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 100%;
  overflow: scroll;
}
.post_backgrounds::-webkit-scrollbar {
  display: none;
}
.post_backgrounds {
  scrollbar-width: none; /* Firefox 64 and later */
}
.post_backgrounds {
  -ms-overflow-style: none; /* IE and Edge */
}
.post_backgrounds img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 10px;
}
.no_bg {
  background: var(--color-light);
  width: 33px;
  height: 33px;
  min-width: 33px;
  min-height: 33px;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 2px;
  border: 2px solid var(--color-primary);
}
.bgHandler {
  height: 240px;
  width: 100%;
  background-size: cover;
}
.bgHandler textarea {
  text-align: center;
  height: 240px;
  margin-top: 10px;
}

/*----post error----*/
.postError {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--color-white);
  opacity: 0.9;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  z-index: 999;
  color: #b94a48;
  animation: anim 0.3s;
  border-radius: 8px;
  padding: 3rem;
}
.postError_error {
  width: 75%;
  font-weight: 600;
}
/*----post error----*/

@keyframes anim {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@media (max-width: 735px) {
  .rlmove {
    right: 10px;
  }
}
@media (max-width: 550px) {
  .postBox {
    width: 96%;
  }

  .post_backgrounds {
    width: 80%;
    overflow-x: auto;
  }
  .post_backgrounds img {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 405px) {
  .preview_actions {
    flex-direction: column;
    gap: 2px;
  }
  .mobile_text {
    width: 50%;
  }
}
@media (max-width: 380px) {
  .post_input {
    font-size: 17px;
  }
  .addtoyourpost {
    justify-content: center;
  }
  .addto_text {
    display: none;
  }
}
.l0 {
  left: 0;
}
.invisible_arrowDown_icon {
  visibility: hidden;
}
.emoji-search {
  background-color: var(--color-white) !important;
}

aside.emoji-picker-react {
  background-color: var(--color-white) !important;
  box-shadow: 0 0 1rem var(--color-primary) !important;
}
ul.emoji-group::before {
  background-color: var(--color-white) !important;
}
.emoji-categories {
  color: var(--color-dark) !important;
}
.post-popup-l-icons-holder {
  display: flex;
  gap: 8px;
  margin-left: 4px;
}
.post-popup-l-icons-holder svg {
  cursor: pointer;
}
.post-p-button-spacing {
  margin-bottom: 10px;
}
.p-popup-i-p-prompt {
  color: var(--color-primary);
  cursor: pointer;
  margin-left: 10px;
}
.user-pics-preview-popup {
  color: var(--color-primary);
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.user-pics-preview-popup svg {
  height: 1rem;
  width: 1rem;
}
.i-p-p-prompt {
  display: flex;
  color: var(--color-dark);
  justify-content: right;
  align-items: right;
  margin-right: 8px;
  cursor: pointer;
}
.i-p-p-clear-images {
  display: flex;
  color: red;
  justify-content: right;
  align-items: right;
  margin-right: 8px;
  cursor: pointer;
}
.images-p-p-closer {
  margin-left: 8px;
  color: var(--color-dark);
  font-size: 1.1rem;
  cursor: pointer;
}
.p-p-blue_btn {
  border: none;
  outline: none;
  background: var(--color-primary);
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}
.p-p-blue_btn {
  transform: translateY(-1px);
}
.c-p-p-post-bg-i-s svg {
  height: 1.5rem;
  width: 1.5rem;
}
.c-p-p-photo-icon-s svg {
  height: 1.5rem;
  width: 1.5rem;
}
.c-p-p-emoji-i-s svg {
  height: 1.6rem;
  width: 1.6rem;
}
.close-i-p-s {
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-i-p-s svg {
  height: 1.6rem;
  width: 1.6rem;
  color: var(--color-dark);
}
