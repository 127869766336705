.banned-message-container {
  width: 100%;
  height: 100%;
  background-color: var(--color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 14px;
  gap: 12px;
}
.banned-msg-u-heading {
  font-size: 2.4rem !important;
}
.banned-msg-texts-b-heading {
  margin-top: 10px;
  font-size: 2.2rem !important;
  color: var(--color-primary);
  line-height: 1.2;
}
.block-icon-banned-c svg {
  height: 5rem;
  width: 5rem;
  color: red;
}
.bottom-banned-explanations-msg {
  font-size: 1.1rem !important;
}
