/* =============================================
MEDIA QUERY FOR SMALL LAPTOPS AND BIG TABLETS
================================================ */

@media screen and (max-width: 1200px) {
  .element_container {
    width: 96%;
  }

  .main .element_container {
    grid-template-columns: 5rem auto 30vw;
    gap: 1rem;
  }

  .left {
    width: 5rem;
    z-index: 5;
  }

  .main .element_container .left .profile {
    display: none;
  }

  .sidebar h3 {
    display: none;
  }

  .left .btn {
    display: none;
  }

  .customize-theme .card-container {
    width: 65%;
  }
}

/* =============================================
MEDIA QUERY FOR SMALL TABLETS AND MOBILE PHONES
================================================ */
@media screen and (max-width: 992px) {
  .nav .search-bar {
    display: none;
  }

  .main .element_container {
    grid-template-columns: 0 auto 5rem;
    gap: 0;
  }

  .main .element_container .left {
    grid-column: 3/4;
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .left-container-section .left .sidebar {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  #explore-search.activeitem {
    display: grid;
  }

  /* ----------NOTIFICATION POPUP------------ */
  .left .notifications-popup {
    position: absolute;
    left: -21rem;
    width: 20rem;
  }

  .left .notifications-popup:before {
    position: absolute;
    top: 1.3rem;
    left: calc(20rem - 0.6rem);
    display: block;
  }

  .main .element_container .middle.activeitem {
    display: block;
    grid-column: 1/3;
  }

  .main .element_container .right {
    display: none;
  }

  .main .element_container .right.activeitem {
    display: block;
    grid-column: 1/3;
  }

  .customize-theme .card-container {
    width: 80%;
  }
}

@media screen and (max-width: 580px) {
  .main .element_container {
    display: block;
  }

  .main .element_container .left {
    translate: 5rem 0;
    transition: translate 0.5s ease;
  }

  .main .element_container .left.activeitem {
    translate: 0;
  }

  .main .element_container .left .menu-toggle {
    display: flex;
  }

  .left .notifications-popup {
    left: -17rem;
    width: 16rem;
  }

  .left .notifications-popup:before {
    left: calc(16rem - 0.6rem);
  }

  .customize-theme .card-container {
    width: 90%;
    padding: 1.5rem;
  }

  .customize-theme .card-container .font-size {
    margin-top: 2rem;
  }

  .customize-theme .background .choose-bg {
    margin-top: 0.3rem;
  }

  .customize-theme .background .choose-bg > div {
    flex-direction: column;
  }

  .customize-theme .background .choose-bg > div > span {
    margin: 0;
    margin-bottom: 0.4rem;
  }
}
