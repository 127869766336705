/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
.bottom-navigation-menu-container {
  width: 100%;
  background: var(--color-white);
  position: fixed;
  bottom: 0;
  z-index: 11;
}
/* --------------------------------- */
/* --------------------------------- */
/* --------------------------------- */
/* --------------------------------- */
.bottom-naigation-menu-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-light);
  width: 100%;
}
/* --------------------------------- */
/* --------------------------------- */
.navigation-bottom {
  position: relative;
  width: 400px;
  width: 100%;
  margin: 10px;
  height: 70px;
  background: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.navigation-bottom ul {
  display: flex;
  width: 350px;
}
.navigation-bottom ul li {
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;
  z-index: 1;
}
.navigation-bottom ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.navigation-bottom ul li a .nav-bottom-icon {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
  color: var(--color-dark);
}
.navigation-bottom ul li.active-bottom-nav a .nav-bottom-icon {
  transform: translateY(-32px);
}
.nav-bottom-icon svg {
  width: 1.5em;
  height: 1.5em;
}
.navigation-bottom ul li a .nav-bottom-text {
  position: absolute;
  color: var(--color-dark);
  font-weight: 400;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
}
.navigation-bottom ul li.active-bottom-nav a .nav-bottom-text {
  opacity: 1;
  transform: translateY(10px);
}
.nav-bottom-indicator {
  position: absolute;
  top: -50%;
  width: 70px;
  height: 70px;
  background: var(--color-primary);
  border-radius: 50%;
  border: 6px solid var(--color-light);
  transition: 0.5s;
}
.nav-bottom-indicator:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -22px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 1px -10px 0 0 var(--color-light);
}
.nav-bottom-indicator:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -22px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-left-radius: 20px;
  box-shadow: -1px -10px 0 0 var(--color-light);
}
.navigation-bottom
  ul
  li:nth-child(1).active-bottom-nav
  ~ .nav-bottom-indicator {
  transform: translateX(calc(70px * 0));
}
.navigation-bottom
  ul
  li:nth-child(2).active-bottom-nav
  ~ .nav-bottom-indicator {
  transform: translateX(calc(70px * 1));
}
.navigation-bottom
  ul
  li:nth-child(3).active-bottom-nav
  ~ .nav-bottom-indicator {
  transform: translateX(calc(70px * 2));
}
.navigation-bottom
  ul
  li:nth-child(4).active-bottom-nav
  ~ .nav-bottom-indicator {
  transform: translateX(calc(70px * 3));
}
.navigation-bottom
  ul
  li:nth-child(5).active-bottom-nav
  ~ .nav-bottom-indicator {
  transform: translateX(calc(70px * 4));
}
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
.circle-user-menu-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-light);
  position: fixed;
  bottom: 0;
  right: 0;
}
.circle-menu {
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-menu .circle-menu-toggle {
  position: absolute;
  width: 75px;
  height: 75px;
  background: var(--color-white);
  color: var(--color-dark);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  cursor: pointer;
  transition: 0.5s;
}
.circle-menu .circle-menu-toggle.circle-menu-active {
  transform: rotate(315deg);
  box-shadow: 0 0 0 68px var(--color-white);
  background: var(--color-light);
  color: var(--color-dark);
}
.circle-menu li {
  position: absolute;
  left: 10px;
  list-style: none;
  transform: rotate(calc(360deg / 8 * var(--circle-menu-i))) translateX(40px);
  transform-origin: 140px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  z-index: 10;
}
.circle-menu.circle-menu-active li {
  visibility: visible;
  opacity: 1;
}
.circle-menu li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  font-size: 1.75em;
  color: var(--color-dark);
  transform: rotate(calc(360deg / -8 * var(--circle-menu-i)));
  border-radius: 50%;
}
.circle-menu.circle-menu-active li.circle-menu-active {
  transform: rotate(calc(360deg / 8 * var(--circle-menu-i))) translateX(12px);
}
.circle-menu-indicator {
  position: absolute;
  left: calc(50% + 2.5px);
  transform-origin: right;
  width: 100px;
  height: 1px;
  background: transparent;
  pointer-events: none;
  transition: 0.5s;
}
.circle-menu-indicator:before {
  content: "";
  position: absolute;
  top: -27.5px;
  left: 72px;
  width: 55px;
  height: 55px;
  background: var(--color-light);
  box-shadow: 0 0 0 6px var(--color-primary);
  border-radius: 50%;
  transition: 0.5s;
  opacity: 0;
}
.circle-menu.circle-menu-active .circle-menu-indicator:before {
  opacity: 1;
  top: -27.5px;
  left: -27.5px;
  background: var(--color-primary);
  box-shadow: 0 0 0 6px var(--color-light);
}
.circle-menu li:nth-child(2).circle-menu-active ~ .circle-menu-indicator {
  transform: translateX(-103px) rotate(0deg);
}
.circle-menu li:nth-child(3).circle-menu-active ~ .circle-menu-indicator {
  transform: translateX(-103px) rotate(45deg);
}
.circle-menu li:nth-child(4).circle-menu-active ~ .circle-menu-indicator {
  transform: translateX(-103px) rotate(90deg);
}
.circle-menu li:nth-child(5).circle-menu-active ~ .circle-menu-indicator {
  transform: translateX(-103px) rotate(135deg);
}
.circle-menu li:nth-child(6).circle-menu-active ~ .circle-menu-indicator {
  transform: translateX(-103px) rotate(180deg);
}
.circle-menu li:nth-child(7).circle-menu-active ~ .circle-menu-indicator {
  transform: translateX(-103px) rotate(225deg);
}
.circle-menu li:nth-child(8).circle-menu-active ~ .circle-menu-indicator {
  transform: translateX(-103px) rotate(270deg);
}
.circle-menu li:nth-child(9).circle-menu-active ~ .circle-menu-indicator {
  transform: translateX(-103px) rotate(315deg);
}
/* ---------------------------- */
.circle-menu .circle-menu-toggle svg {
  width: 3rem;
  height: 3rem;
}
.circle-menu li a svg {
  width: 1.75rem;
  height: 1.75rem;
}
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
.circle-user-menu-content {
  border-radius: 50%;
  overflow: visible;
  transform: scale(0.2);
  bottom: -25px;
  right: -25px;
  z-index: 11;
}
.circle-menu {
  transform: scale(4.6);
}
