.not-logged-in-header-search-menu-prompt {
  border-radius: 10px;
  margin-top: 12px;
  padding: 4px;
}
.help-page-main {
  position: relative;
  top: 5.4rem;
}
.help-page-element_container {
  display: grid;
  padding-bottom: 2rem;
  position: relative;
  width: 80%;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .help-page-element_container {
    width: 96%;
  }
}
@media screen and (max-width: 580px) {
  .help-page-main .help-page-element_container {
    display: block;
  }
}
.help-page-main-center {
  margin: 0 auto;
  width: 100%;
}
.help-explore-search {
  /* background-color: red; */
  width: 100%;
  display: grid;
  grid-template-columns: 2rem auto 6rem;
  background: var(--color-white);
  border-radius: 12px;
  padding: var(--search-padding);
  margin-bottom: 1rem;
}
.help-explore-search input[type="search"] {
  background: var(--color-white);
  width: 100%;
  margin-left: 1rem;
  font-size: 0.9rem;
  color: var(--color-dark);
}
.help-explore-search input[type="text"] {
  background: var(--color-white);
  width: 100%;
  margin-left: 1rem;
  font-size: 0.9rem;
  color: var(--color-dark);
}
.help-explore-search select {
  background: transparent;
  height: 100%;
  padding-left: 0.5rem;
  text-align: center;
  color: var(--color-dark);
  border-left: 2px solid var(--color-light);
}
@media screen and (min-width: 992px) {
  .help-explore-search {
    display: none;
  }
}
.col-wrapper {
  display: grid;
  grid-template-columns: 1fr;

  gap: 24px;
  margin: 24px 0;
}
@media screen and (min-width: 768px) {
  .col-wrapper {
    grid-template-columns: 1.2fr 0.8fr;
  }
}
.tabs-wrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.top-tab-active {
  cursor: pointer;
  font-weight: bold;
  color: var(--color-dark);
  font-size: 1.2rem;
}
.top-tab {
  cursor: pointer;
  font-weight: normal;
  color: var(--color-gray);
  font-size: 1.2rem;
}
.h-c-help-box {
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 22px;
}
.centered-help-p-div {
  display: flex;
  justify-content: center;
  gap: 11px;
}
.help-contact-img-icon-link {
  display: block;
  text-decoration: none;
}
.help-contact-img-icon {
  width: 30px;
  height: 30px;
}

.help-contact-us-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 6px;
  border: 1.1px solid var(--color-dark);
  border-radius: 5px;
  box-sizing: border-box;
  font-family: inherit;
  background: transparent;
  color: var(--color-dark);
}
.help-contact-us-textarea {
  width: 100%;
  padding: 5px;
  margin-bottom: 6px;
  border: 1.1px solid var(--color-dark);
  border-radius: 5px;
  box-sizing: border-box;
  font-family: inherit;
  background: transparent;
  color: var(--color-dark);
  resize: none;
  height: 90px;
}
.help-contact-us-submit-button {
  border: 0;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  background: var(--color-primary);
  transition: all 0.45s;
}
.help-contact-us-submit-button:hover {
  transform: scale(0.96);
}
.help-contact-us-submit-button-div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.help-contact-us-submit-button-div svg {
  width: 1.2rem;
  height: 1.2rem;
}
.other-col-wrapper {
  margin-top: 10px;
}
.help-developer-image-wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}
.help-developer-image {
  min-width: 100px;
  min-height: 100px;
  max-width: 100%;
  width: 220px;
  object-fit: contain;
}
.help-page-developer-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.help-page-developer-section p {
  margin: 5px 0;
}
.help-page-developer-section svg {
  height: 20px;
  width: 20px;
}
.help-contact-svg-icon svg {
  width: 30px !important;
  height: 30px !important;
  transition: all 0.45s;
}
.help-contact-svg-icon svg:hover {
  transform: scale(1.15);
  color: var(--color-primary);
}
.help-developer-page-button {
  border: 0.4px solid transparent;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76%;
  color: white;
  background: var(--color-primary);
  transition: all 0.45s;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.help-developer-page-button:hover {
  color: var(--color-dark);
  border: 0.4px solid var(--color-dark);
}
.help-developer-page-button:after {
  content: "";
  background: var(--color-light);
  position: absolute;
  z-index: -1;
  left: -20%;
  right: -20%;
  top: 0;
  bottom: 0;
  transform: skewX(-45deg) scale(0, 1);
  transition: all 0.5s;
}
.help-developer-page-button:hover:after {
  transform: skewX(-45deg) scale(1, 1);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.help-bottom-section-footer {
  text-align: center;
  padding-top: 15px;
}
.help-bottom-link-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.help-bottom-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
.help-page-bottom-link {
  text-decoration: none;
  cursor: pointer;
  color: var(--color-dark);
}
.help-page-bottom-link-texts {
  margin: 0;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.help-page-bottom-link-texts svg {
  width: 24px;
  height: 24px;
  margin-bottom: 3px;
}
.help-page-feed .help-page-feed-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.help-p-f-user {
  display: flex;
  align-items: start;
  gap: 0.85rem;
}
.c-p-user-feed-post-help {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  gap: 4px;
}
.user-feed-icons-help svg {
  cursor: pointer;
  width: 1.3rem;
  height: 1.3rem;
}
.c-p-user-feed-post-help svg {
  width: 16px;
  height: 16px;
}
.help-page-feed {
  max-width: 100%;
}
.help-feed-caption {
  padding: 0 1rem;
  font-size: 0.9rem;
  line-height: 1.3rem;
}
.help-feed-caption {
  word-break: break-word;
}
.help-userfeed-photo {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  max-width: 100%;
  border-radius: var(--card-border-radius);
  overflow: hidden;
  background-color: var(--color-light);
}
.help-userfeed-photo img {
  border-radius: var(--card-border-radius);
  object-fit: cover;
}
.help-feed-slider {
  height: 320px;
  max-width: 100%;
  position: relative;
}
.help-feed-slider .list .useritem {
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
  opacity: 0;
}
.help-feed-slider .list .useritem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.help-feed-slider .list .useritem.useractivei {
  opacity: 1;
  z-index: 1;
}

.help-feed-slider .userarrows {
  position: absolute;
  top: 6%;
  right: 5%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.help-feed-slider .userarrows button {
  background: none;
  border: none;
  font-family: monospace;
  border-radius: 5px;
  font-size: x-large;
  color: var(--color-dark);
  transition: all 0.45s;
}
.help-feed-slider .userarrows button:hover {
  transform: scale(1.3);
}
/* ----------------------------- */
/* ----------------------------- */
/* ----------------------------- */
.help-feed-slider .userthumbnail {
  position: absolute;
  bottom: 12px;
  z-index: 2;
  display: flex;
  gap: 10px;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  overflow: auto;
  justify-content: center;
}
.help-feed-slider .userthumbnail::-webkit-scrollbar {
  width: 0;
}
.help-feed-slider .userthumbnail .useritem {
  width: 40px;
  height: 40px;
  filter: brightness(0.5);
  transition: 0.5s;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 50% !important;
}
.help-feed-slider .userthumbnail .useritem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.help-feed-slider .userthumbnail .useritem.useractivei {
  filter: brightness(1.4);
  border: 1px solid var(--color-primary);
}

@media screen and (max-width: 678px) {
  .help-feed-slider .userthumbnail {
    justify-content: center;
  }
  .help-feed-slider .userarrows {
    top: 5%;
  }
}

.help-feed-slider .userthumbnail::-webkit-scrollbar {
  display: none !important;
}
.help-feed-slider .userthumbnail {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
/* --------------------- */
/* --------------------- */
/* --------------------- */
/* --------------------- */
.help-page-feed .action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  margin: 0.6rem;
}

.help-page-feed .action-buttons > div {
  display: flex;
  gap: 0.6rem;
}

.help-page-feed .like img {
  width: 1.5rem;
  height: 1.5rem;
}

.help-page-feed .liked-by {
  display: flex;
}

.help-page-feed .liked-by span {
  width: 1.4rem;
  height: 1.4rem;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--color-white);
  margin-left: -0.6rem;
}

.help-page-feed .liked-by span img {
  width: 1.4rem;
  height: 1.4rem;
  object-fit: cover;
}

.help-page-feed .liked-by span:first-child {
  margin: 0;
}

.help-page-feed .liked-by p {
  margin-left: 0.5rem;
}

.help-page-feed .action-buttons span,
.help-page-feed .show-comments {
  cursor: pointer;
}

.help-page-feed .action-buttons span:hover,
.help-page-feed .show-comments:hover {
  color: var(--color-primary);
}
.help-page-feed .share-popup {
  z-index: 2;
  top: -60px;
  left: -10px;
}
.help-page-feed .post_menu {
  top: 2.1rem;
  right: 0;
}
.help-page-advertisement {
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.help-advertisement-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.help-advertisement-heading i svg {
  height: 1.4rem;
  width: 1.4rem;
}
/* ------------------------------- */
.help-advertisement-photo-c-s {
  border-radius: var(--card-border-radius);
  overflow: hidden;
  margin-bottom: 0.3rem;
  max-width: 100%;
  max-height: 250px;
  min-height: 100px;
  background-color: var(--color-light);
}
.help-advertisement-photo-c-s img {
  border-radius: var(--card-border-radius);
  object-fit: cover;
  width: 100%;
  transition: all 0.45s;
  cursor: pointer;
}
.help-advertisement-photo-c-s img:hover {
  transform: scale(1.1);
}
