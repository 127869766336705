.info-icon-container {
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
}
.info-profile {
  display: flex;
  align-items: center;
  gap: 10px;
}
.info-text {
  word-break: break-word;
}
.u-info-btn-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 2px;
}
.u-info-btn-container > div {
  flex: 1;
}
.u-p-i-btn {
  border-radius: 8px;
  width: 100%;
}
.u-p-i-btn:hover {
  border: 0.5px solid var(--color-dark);
}
.bio-btn-swap-s svg {
  height: 1rem;
  width: 1rem;
}
.bio-btn-swap-s {
  display: flex;
  align-items: center;
  background-color: var(--color-light);
  color: var(--color-dark);
}
.add-bio-h-l-s {
  height: 0.4px;
  background: var(--color-dark);
  border-radius: 12px;
  margin: 8px 0;
}
.add-bio-wrap {
  display: flex;
  flex-direction: column;
}
.textarea-bio-d-s {
  width: 100%;
  height: 85px;
  resize: none;
  padding: 1rem;
}
.details-input-bio {
  margin-top: 2px;
  background-color: var(--color-light);
  color: var(--color-dark);
  border-radius: 8px;
}
.details-input-bio:focus {
  border: 0.65px solid var(--color-primary);
}
.flex-p-s {
  display: flex;
  align-items: center;
  gap: 10px;
}
.remaining {
  font-size: 12px;
  color: var(--color-gray);
  align-self: flex-end;
  margin-top: 2px;
}
.add-bio-wrap .flex-p-s {
  margin-top: 4px;
  justify-content: space-between;
}
.gray-cancel-btn {
  border: none;
  outline: none;
  padding: 9px 14px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}
.primary-c-btn {
  border: none;
  outline: none;
  background: var(--color-primary);
  padding: 9px 14px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}
.flex-left {
  font-size: 12px;
  gap: 6px;
}
.flex-left i {
  transform: scale(0.85);
  filter: invert(10%);
}
.infosBox {
  max-height: 500px;
  overflow: hidden;
}
.details_wrapper {
  max-height: 450px;
  overflow: scroll;
  padding: 1rem;
}
.details_col {
  display: flex;
  flex-direction: column;
}
.details_col span:first-of-type {
  font-weight: 600;
  font-size: 1.1rem;
}
.details_col span:last-of-type {
  color: var(--color-primary);
}
.details_col span:last-of-type:hover {
  font-weight: 500;
}
.details_header {
  margin-top: 15px;
  font-weight: 600;
}
.add_details_flex {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.85rem;
  margin-top: 11px;
  cursor: pointer;
}
.underline-h:hover {
  text-decoration: underline;
}
.add_details_flex .edit-d-icon {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}
.select_rel {
  height: 34px;
  outline: none;
  border: none;
  background: var(--color-light);
  margin: 10px 0;
  padding: 0 10px;
  border-radius: 10px;
  color: var(--color-dark);
  font-size: 1rem;
}
@media (max-width: 330px) {
  .details_wrapper {
    max-height: 400px;
  }
}
.edit-d-icon svg {
  height: 1rem;
  width: 1rem;
}
.edit-detail-p-i-s svg {
  height: 1rem;
  width: 1rem;
}
.rounded-plus-icon-d svg {
  height: 2rem;
  width: 2rem;
  color: var(--color-primary);
}
.link-open-details-c {
  z-index: 999999999;
  position: fixed;
  left: 0;
  top: 170px;
  width: 100%;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}
.website-details-link-p {
  cursor: pointer;
}
.website-error {
  margin-top: 2px;
  color: red;
}
